// Internal content
export const internalContent = {
  width: { xs: '100%', md: 'calc(100% - 300px)' },
};

// Internal navigation
export const internalNavigationTabsMenu = {
  height: 'fit-content',
  width: { xs: '100%', md: '300px' },
  padding: 0,
  border: '1px solid rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
};

export const tabsContainer = {
  marginBottom: '16px',
};

export const tab = {
  alignItems: { xs: 'center', md: 'flex-start' },
  maxWidth: '100%',
  minHeight: '42px',
  padding: '0px 24px',
  color: 'text.primary',
  fontSize: { xs: '18px', md: '16px' },
  fontFamily: 'primaryBold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(50, 50, 50, 0.05)',
  },
};

// Tiles

export const internalNavigationTilesMenu = {
  boxShadow: 'none',
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto 32px',
};

export const tilesContainer = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(auto-fit, minmax(88px, max-content))',
    sm: 'repeat(auto-fit, minmax(100px, max-content))',
  },
  gridGap: { xs: '16px', sm: '32px' },
  padding: 'initial',
  justifyContent: 'center',
  marginTop: { xs: '8px', sm: '16px' },
};

export const tile = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: { xs: '88px', sm: '100px' },
  cursor: 'pointer',
};

export const tileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: { xs: '88px', sm: '100px' },
  height: { xs: '88px', sm: '100px' },
  marginBottom: '4px',
  backgroundColor: 'primary.main',
  borderRadius: '8px',
  boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
  '&:hover': {
    backgroundColor: '#148C42',
  },
};

export const tileImage = {
  width: '50px',
  height: '50px',
};

// Profile info
export const profileInfoContainer = {
  display: 'flex',
  alignItems: 'center',
  jusufyContent: 'flex-start',
  flexDirection: { xs: 'column', md: 'row' },
  gap: { xs: '8px', md: '16px' },
  padding: { xs: '16px', md: '16px 24px 24px' },
};

export const avatar = {
  width: '66px',
  height: '66px',
  background: '#17AA4F',
};

export const userName = {
  fontFamily: 'primaryBold',
  fontSize: { xs: '20px', md: '18px' },
};

export const status = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

export const statusTitle = {
  color: '#EFC75E',
  fontFamily: 'primaryBold',
  fontSize: { xs: '16px', md: '14px' },
  textAlign: { xs: 'center', md: 'left' },
};

export const iconButton = {
  height: '24px',
  width: '24px',
  backgroundColor: 'rgba(230, 230, 230, 0.8)',
  color: 'text.primary',
  '&:hover': {
    backgroundColor: 'rgba(210, 210, 210, 0.8)',
  },
};

export const backButton = {
  boxShadow: 'none',
  fontSize: '16px',
  color: 'black',
  padding: 'initial',
  marginBottom: '8px',
};

export const editIcon = {
  fontSize: '18px',
};

export const backButtonArrow = {
  fontSize: '18px',
};
