export const LANGUAGE_CONFIG = {
  id: 0,
  name: 'IUVO Profile',
  defaultLanguage: {
    id: 0,
    language: 'English',
    code: 'en',
  },
  defaultPlatformId: 0,
  filePath: 'string',
  languages: [
    {
      id: 0,
      language: 'English',
      code: 'en',
    },
    {
      id: 1,
      language: 'Български',
      code: 'bg',
    },
    {
      id: 2,
      language: 'Deutsch',
      code: 'de',
    },
    {
      id: 3,
      language: 'Español',
      code: 'es',
    },
  ],
  description: 'string',
};

export const APP_LANGUAGE = 'app_language';
