/* eslint-disable indent */
import { useMutation } from 'react-query';
import { MutationFunction } from 'react-query/types/core/types';

import { errorResultHandler } from 'utils/helpers/errorResultHandler';
import { successResultHandler } from 'utils/helpers/successResultHandler';
import { Error } from 'api/types/userTypes';
import { queryClient } from './queryClient';

export const useMutationRequest = <TData, TVariables>(
  func: MutationFunction<TData, TVariables>,
  invalidateKeys?: string | string[],
  customSuccessMessage?: string
) =>
  useMutation(func, {
    onSuccess: async (response: any) => {
      let keys = invalidateKeys;

      if (keys) {
        if (!Array.isArray(keys)) {
          keys = [keys];
        }
        keys.forEach((x) => queryClient.invalidateQueries(x));
      }
      successResultHandler(response, customSuccessMessage);
    },
    onError: async (error: Error) => {
      errorResultHandler(error);
    },
  });
