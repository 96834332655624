// checkbox
export const checkbox = {
  color: 'text.primary',
};

export const errorMessage = {
  fontFamily: 'primaryRegular', 
  fontSize: '13px',
}

// textArea
export const textArea = {
  width: '350px',
  borderRadius: '5px',
  border: '1px solid rgba(0, 0, 0, 0.35)',
};

// text field
export const textFieldContainer = {
  display: { xs: 'block', sm: 'flex' },
  justifyContent: 'space-between',
};

export const textFieldWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
};

export const standardTextField = {
  width: { xs: '100%', sm: '50%' },
  '& .MuiInputBase-root, & .MuiFormLabel-root': {
    fontSize: '16px',
  },
  '& .MuiInputBase-root.Mui-disabled:before': {
    borderBottomStyle: 'solid',
  },
};

export const disabledTextField = {
  '& .MuiInputBase-root, & .MuiFormLabel-root': {
    fontSize: '16px',
  },
};

export const outlinedTextField = {
  width: '784px',
  borderRadius: '5px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
};

export const textFieldLabel = {
  display: 'flex',
  alignItems: 'baseline',
  width: { xs: '100%', sm: '50%' },
  fontSize: { xs: '16px', sm: '18px' },
  fontFamily: 'primaryRegular',
};

export const icons = {
  cursor: 'pointer',
  color: 'text.primary',
  fontSize: '1.2rem',
};

export const submitCheckButton = {
  padding: '0px',
  boxShadow: 'none',
  '&:hover': { background: 'none' },
};

export const submitButtonWrapper = {
  display: { xs: 'flex', sm: 'grid' },
  gap: '16px',
  justifyContent: 'center',
  justifyItems: 'center',
  margin: { xs: '24px auto', lg: '56px 0px' },
};

export const submitButton = {
  maxWidth: { xs: '100px', sm: '140px' },
  fontSize: { xs: '16px', sm: '18px' },
};

// upload

export const fileUploadWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const uploadWrapper = {
  padding: { xs: '8px 0 16px', sm: '0 0 32px' },
  boxShadow: '0px 30px 45px rgba(0, 0, 0, 0.1)',
};

export const uploadContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  minWidth: '100%',
};

export const button = {
  width: '100%',
  maxWidth: '250px',
  height: { xs: '32px', sm: '40px' },
  margin: '16px 0',
  padding: '24px 32px',
  fontSize: '16px',
};

export const headings = {
  display: 'inline-block',
  verticalAlign: 'top',
  justifyContent: 'center',
  margin: '16px 0',
  color: 'text.primary',
  fontSize: { xs: '20px', sm: '24px' },
  fontFamily: 'primaryBold',
  textTransform: 'uppercase',
};

export const subheading = {
  color: 'text.primary',
  fontFamily: 'primaryBold',
  fontSize: '16px',
};

export const uploadMessage = {
  padding: '0 30px',
};

export const biggerFileErrorMessage = {
  marginTop: '16px',
  color: 'error.main',
};

// uploaded files

export const uploadedFileWrapper = {
  textAlign: 'center',
};

export const item = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginTop: '16px',
};

export const closeIcon = {
  cursor: 'pointer',
};

export const uploadButton = {
  width: '100%',
  maxWidth: '150px',
  height: { xs: '32px', sm: '40px' },
  padding: '24px 32px',
  fontSize: '16px',
};
