import React from 'react';

import { Box, CardContent, Typography } from '@mui/material';

import {
  cardContent,
  stepDescriptionStyle,
  stepContainer,
  stepHeadingStyle,
  verificationStepsImage,
} from 'styles/verificationStyles';

interface MainVerificationStepsProps {
  verificationStepImage: string;
  verificationStepImageAlt: string;
  stepHeading: string;
  stepDescription?: string;
}

export const MainVerificationSteps = (props: MainVerificationStepsProps) => {
  const {
    verificationStepImage,
    verificationStepImageAlt,
    stepHeading,
    stepDescription,
  } = props;

  return (
    <Box sx={stepContainer}>
      <img
        src={verificationStepImage}
        alt={verificationStepImageAlt}
        style={verificationStepsImage}
      />
      <CardContent sx={cardContent}>
        <Typography variant="h5" sx={stepHeadingStyle}>
          {stepHeading}
        </Typography>

        <Typography variant="body1" sx={stepDescriptionStyle}>
          {stepDescription}
        </Typography>
      </CardContent>
    </Box>
  );
};
