import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { documentsWrapper } from 'styles/documentsStyles';
import { PageTitle } from 'components/common/PageTitle';
import { CurrentDocument } from 'components/features/documents/CurrentDocument';
import { DocumentsHistory } from 'components/features/documents/DocumentsHistory';
import { RequestedDocuments } from 'components/features/documents/RequestedDocuments';

export const DocumentsScreen = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();

  return (
    <>
      <PageTitle title={translate('navigation.documents')} />

      {userData?.userId ? (
        <Box sx={documentsWrapper}>
          <CurrentDocument />
          <RequestedDocuments />
          <DocumentsHistory />
        </Box>
      ) : (
        <CircularProgress color="primary" />
      )}
    </>
  );
};
