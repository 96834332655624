import React, { Dispatch, useState } from 'react';

import { FieldValues, useForm, UseFormHandleSubmit } from 'react-hook-form';

import { TextField, Box, Typography } from '@mui/material';

import {
  textFieldContainer,
  outlinedTextField,
  textFieldLabel,
  standardTextField,
  disabledTextField,
} from 'styles/inputFieldsStyles';
import { SingleEditIcons } from 'components/common/inputFields/SingleEditIcons';
import { handleSpacesForRequiredFields } from "utils/helpers/formFieldsHelper";
import { useTranslations } from "utils/context/TranslationContext";
import { inputError } from "styles/commonStyles";

interface InputProps {
  id: string;
  type: string;
  label: string;
  variant: 'standard' | 'filled' | 'outlined' | undefined;
  required: boolean;
  handleDone: (
    fieldName: string,
    fieldValue: string,
    setIsEditing: Dispatch<React.SetStateAction<boolean>>
  ) => UseFormHandleSubmit<FieldValues> | void;
  fieldValue?: string;
  disabled?: boolean;
  patternValue?: RegExp;
  patternMessage?: string;
  sx?: {};
  minValue?: number;
  maxValue?: number;
}

export const TextFieldComponent = (props: InputProps) => {
  const {
    id,
    type,
    label,
    variant,
    required,
    handleDone,
    fieldValue,
    disabled,
    patternValue,
    patternMessage,
    sx,
    minValue,
    maxValue
  } = props;

  const {
    resetField,
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const { translate } = useTranslations();

  const [temporalInputValue, setTemporalInputValue] = useState(fieldValue);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <form
      onSubmit={handleSubmit(() =>
        handleDone(id, getValues()[id], setIsEditing)
      )}
    >
      <Box sx={{ ...textFieldContainer, ...sx || null }}>
        <Typography variant="h5" sx={textFieldLabel}>
          {label}
        </Typography>
        <TextField
          id={id}
          variant={variant}
          defaultValue={fieldValue}
          disabled={disabled || !isEditing}
          InputLabelProps={{ required: true }}
          {...register(id, {
            required: required && translate('messages.requiredFieldMessage'),
            validate: handleSpacesForRequiredFields,
            pattern: {
              value: patternValue || /\w/,
              message: patternMessage as string,
            },
            minLength: {
              value: minValue as number,
              message: translate('messages.minErrorMessage', {
                minValue: minValue?.toString() as string,
              }),
            },
            maxLength: {
              value: maxValue as number,
              message: translate('messages.maxErrorMessage', {
                maxValue: maxValue?.toString() as string,
              }),
            }
          })}
          type={type}
          error={Boolean(errors[id])}
          helperText={errors[id]?.message as string}
          sx={{
            ...inputError,
            ...standardTextField,
            ...(variant === 'outlined' && {
              ...outlinedTextField,
            }),
            ...(type === 'text' && { ...disabledTextField }),
          }}
          InputProps={{
            endAdornment: type === 'text' && (
              <SingleEditIcons
                id={id}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                resetField={resetField}
                temporalInputValue={temporalInputValue}
                setTemporalInputValue={setTemporalInputValue}
                getValues={getValues}
                defaultValue={fieldValue}
              />
            ),
          }}
        />
      </Box>
    </form>
  );
};
