import { KeycloakConfigDefault, KeycloakServiceDefault, KeycloakServiceCustom, KeycloakConfigCustom } from 'virava';

import { appConfig } from './app.config';

export const authConfig: KeycloakConfigDefault = {
  clientId: appConfig.keycloakClientId,
  baseUrl: appConfig.keycloakBaseUrl,
  realm: appConfig.keycloakRealm,
};

export const configCustom: KeycloakConfigCustom = {
  ...authConfig,
  gatewayBaseUrl: 'https://dev.api.platform.iuvo-group.com/gateway/api'
};

export const viravaService = new KeycloakServiceDefault();  

export const viravaCustom = new KeycloakServiceCustom();
