import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(18, 168, 75, 1)',
    },
    background: {
      default: 'rgba(255, 255, 255, 1)',
    },
    text: {
      primary: 'rgba(46, 46, 46, 1)',
      secondary: 'rgba(255, 255, 255, 1)',
    },
    warning: {
      main: 'rgba(228, 18, 18, 1)',
    },
    error: {
      main: 'rgba(228, 18, 18, 1)',
    },
    divider: 'rgba(0, 0, 0, 0.25)',
  },
  typography: {
    h1: {
      fontFamily: 'primaryBold',
    },
    h2: {
      fontFamily: 'primaryBold',
    },
    h5: {
      fontFamily: 'primaryBold',
    },
    h6: {
      fontFamily: 'primarySemiBold',
    },
    body1: {
      fontFamily: 'primaryRegular',
    },
    body2: {
      fontFamily: 'primaryItalic',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            opacity: '1',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            left: '0',
            width: '4px',
            bgcolor: 'primary.main',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          maxHeight: '50px',
          padding: '5px 80px',
          borderRadius: '50px',
          boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
          textTransform: 'capitalize',
          fontSize: '24px',
          fontFamily: 'primaryBold',
        },
        containedPrimary: {
          color: 'rgba(255, 255, 255, 1)',
          background: '#17AA4F',
          '&:hover': {
            backgroundColor: '#148C42',
          },
        },
        containedSecondary: {
          color: 'rgba(46, 59, 62, 0.85)',
          background: '#F1F6F7',
          '&:hover': {
            backgroundColor: 'rgba(210, 210, 210, 1)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            path: {
              margin: 0,
            },
            border: '1px solid',
            borderColor: 'text.secondary',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '3px',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          maxWidth: '360px',
          width: '100%',
          transition: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
});

export default theme;
