import { toast } from 'react-toastify';

import {
  MESSAGE_SUCCESS,
  MESSAGE_UNEXPECTED_ERROR,
} from 'utils/constants/messages';

export const showErrorToast = (errorMessage?: string) => {
  if (errorMessage?.includes('TRIGGER_ID_VERIFICATION')) {
    return;
  }
  toast.error(errorMessage || MESSAGE_UNEXPECTED_ERROR);
};

export const showSuccessToast = (message?: string) => {
  toast.success(message || MESSAGE_SUCCESS);
};
