import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid, Box, Button } from '@mui/material';

import ShuftiVerificationImage from 'assets/images/shufti-verification.png';
import Questionnaire from 'assets/images/questionnaire.png';
import AdditionalDocuments from 'assets/images/additional-documents.png';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { verificationBasedOnActions } from 'utils/helpers/verification';
import { submitButtonWrapper } from 'styles/inputFieldsStyles';
import {
  shuftiVerificationButton,
  stepsWrapper,
} from 'styles/verificationStyles';
import { mainWrapper } from 'styles/mainWrapperStyles';
import { MainVerificationSteps } from 'components/features/verifications/MainVerificationSteps';
import { PageTitle } from 'components/common/PageTitle';
import { MainWrapper } from 'components/common/MainWrapper';

export const MainVerificationScreen = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const history = useNavigate();

  const [hasShufti, setHasShufti] = useState(true);

  useEffect(() => {
    localStorage.removeItem('shuftiReference');
    setHasShufti(false);
  }, []);

  if (hasShufti) {
    return null;
  }

  return (
    <MainWrapper styles={mainWrapper}>
      <PageTitle title={translate('labels.verificationProcess')} />

      <Grid container sx={stepsWrapper}>
        <MainVerificationSteps
          verificationStepImage={ShuftiVerificationImage}
          verificationStepImageAlt={translate('altText.shuftiVerification')}
          stepHeading={translate('labels.firstStepHeading')}
          stepDescription={translate('labels.firstStepDescription')}
        />

        <MainVerificationSteps
          verificationStepImage={Questionnaire}
          verificationStepImageAlt={translate('altText.questionnaire')}
          stepHeading={translate('labels.secondStepHeading')}
          stepDescription={translate('labels.secondStepDescription')}
        />

        <MainVerificationSteps
          verificationStepImage={AdditionalDocuments}
          verificationStepImageAlt={translate('altText.AdditionalDocuments')}
          stepHeading={translate('labels.thirdStepHeading')}
        />
      </Grid>

      <Box sx={submitButtonWrapper}>
        <Button
          sx={shuftiVerificationButton}
          variant="contained"
          onClick={() =>
            verificationBasedOnActions(
              (userData.requiredActions as Array<{ type: string }>)[0]?.type,
              history
            )
          }
        >
          {translate('buttons.start')}
        </Button>
      </Box>
    </MainWrapper>
  );
};
