import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, IconButton, Link, Tooltip } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import IuvoLogoWhite from 'assets/images/iuvo-logo-white.svg';
import { MainWrapper } from 'components/common/MainWrapper';
import { HeaderFunds } from 'components/common/header/HeaderFunds';
import { HeaderProfile } from 'components/common/header/HeaderProfile';
import { HeaderDrawerNavigation } from 'components/common/header/HeaderDrawerNavigation';
import { LanguageMenu } from 'components/common/i18n/LanguageMenu';
import {
  ROUTE_PATH_TRANSACTION_DEPOSIT,
  ROUTE_PATH_TRANSACTION_WITHDRAW,
} from 'utils/constants/routes';
import { IUVO_PLATFORM_PATH } from 'utils/constants/mainNavigation';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { verificationBasedOnStatus } from 'utils/helpers/verification';
import {
  actionButton,
  headerWrapper,
  headerButton,
  headerRightSide,
  iconButton,
  logoImage,
  logoWrapper,
  fundsInHeader,
  fundsInHeaderWrapper,
} from 'styles/headerStyles';
import theme from 'styles/customTheme';
import { mainWrapperCommon } from 'styles/mainWrapperStyles';

export const Header = () => {
  const history = useNavigate();
  const { translate } = useTranslations();
  const { userData } = useUserData();

  const showNavigation = useMediaQuery(theme.breakpoints.down('md'));
  const showLanguageMenu = useMediaQuery(theme.breakpoints.down('md'));
  const showActionButtons = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={headerWrapper}>
      <MainWrapper styles={mainWrapperCommon}>
        <Link sx={logoWrapper} href={IUVO_PLATFORM_PATH}>
          <img
            style={logoImage}
            src={IuvoLogoWhite}
            alt={translate('labels.logoLabel')}
          />
        </Link>

        <Box sx={fundsInHeader}>
          {translate('labels.accountBalance')}:
          <HeaderFunds
            funds={userData.funds}
            additionalStyle={fundsInHeaderWrapper}
          />
        </Box>
        <Box sx={headerRightSide}>
          {showActionButtons && (
            <>
              <Button
                sx={actionButton}
                variant="contained"
                aria-label={translate('labels.deposit')}
                size="large"
                onClick={() => {
                  if (userData.permissions?.deposit) {
                    history(ROUTE_PATH_TRANSACTION_DEPOSIT);
                  } else {
                    verificationBasedOnStatus(
                      userData.userStatus as string,
                      (userData.requiredActions as Array<{ type: string }>)[0]
                        .type,
                      history
                    );
                  }
                }}
              >
                {translate('buttons.deposit')}
              </Button>

              <Button
                sx={actionButton}
                variant="contained"
                color="secondary"
                aria-label={translate('labels.withdraw')}
                size="large"
                onClick={() => {
                  if (userData.permissions?.withdraw) {
                    history(ROUTE_PATH_TRANSACTION_WITHDRAW);
                  } else {
                    verificationBasedOnStatus(
                      userData.userStatus as string,
                      (userData.requiredActions as Array<{ type: string }>)[0]
                        .type,
                      history
                    );
                  }
                }}
              >
                {translate('buttons.withdraw')}
              </Button>
            </>
          )}

          <Tooltip title={translate('labels.shoppingCart')}>
            <IconButton
              sx={{ ...headerButton, ...iconButton }}
              aria-label={translate('labels.shoppingCart')}
              size="large"
            >
              <ShoppingCart />
            </IconButton>
          </Tooltip>

          <HeaderProfile />

          {showNavigation && <HeaderDrawerNavigation />}
          {showLanguageMenu && <LanguageMenu isHeaderVersion />}
        </Box>
      </MainWrapper>
    </Box>
  );
};
