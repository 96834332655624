// Route paths constants
export const ALL_ROUTES_WILDCARD = '*';
export const ROUTE_PATH_HOME = '/';

// Main
export const ROUTE_PATH_VERIFICATION = '/verification';
export const ROUTE_PATH_PROFILE = '/profile';
export const ROUTE_PATH_TRANSACTION = '/transaction';
export const ROUTE_PATH_PROFILE_AVATAR = `/avatar`;
export const ROUTE_PATH_DOCUMENTS = '/documents';

// Verification routes
export const ROUTE_PATH_SMALL_QUESTIONNAIRE = `${ROUTE_PATH_VERIFICATION}/small-questionnaire`;
export const ROUTE_PATH_DOCUMENT_VERIFICATION = `${ROUTE_PATH_VERIFICATION}/document-verification`;
export const ROUTE_PATH_SHUFTI = `${ROUTE_PATH_VERIFICATION}/shufti`;

// Transactions routes
export const ROUTE_PATH_TRANSACTION_DEPOSIT = `${ROUTE_PATH_TRANSACTION}/deposit`;
export const ROUTE_PATH_TRANSACTION_WITHDRAW = `${ROUTE_PATH_TRANSACTION}/withdraw`;
export const ROUTE_PATH_TRANSACTION_BANK_TRANSFER = `${ROUTE_PATH_TRANSACTION}/bank-transfer`;
export const ROUTE_PATH_TRANSACTION_CARD_DEPOSIT = `${ROUTE_PATH_TRANSACTION}/card-deposit`;
export const ROUTE_PATH_TRANSACTION_SUCCESSFUL = `${ROUTE_PATH_TRANSACTION}/successful`;
export const ROUTE_PATH_TRANSACTION_WENT_WRONG = `${ROUTE_PATH_TRANSACTION}/went-wrong`;
export const ROUTE_PATH_TRANSACTION_FAILED = `${ROUTE_PATH_TRANSACTION}/failed`;

// Avatar routes
export const ROUTE_PATH_UPLOAD_AVATAR = `${ROUTE_PATH_PROFILE_AVATAR}/upload`;

// Documents
export const ROUTE_PATH_UPLOAD_DOCUMENT = `${ROUTE_PATH_DOCUMENTS}/upload-document`;
