export const alignItemsCenter = {
  alignItems: 'center',
};

export const pageTitle = {
  marginBottom: '40px',
  fontSize: '30px',
};

export const textTransformCapitalize = {
  textTransform: 'capitalize',
};

export const transformOrigin: {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
} = {
  vertical: 'top',
  horizontal: 'center',
};

export const anchorOrigin: {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
} = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const loader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  m: '16px',
};

export const inputError = {
  '& .MuiFormHelperText-root': {
    fontFamily: 'primaryRegular',
    fontSize: '13px',
  },
}