import { FieldValues } from 'react-hook-form';
import {REQUIRED_LABEL} from 'utils/constants/identifiers';

export const handleRequiredFields = (err: FieldValues, id: string, message: string): string => 
  err[id]?.type === REQUIRED_LABEL
    ? message
    : err[id]?.message;

export const handleSpacesForRequiredFields = (value?: string): boolean =>
  value ? !!value.trim() : true;

export const check = (
  errors: FieldValues,
  type: string,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!errors[type]) {
    setIsEditing(false);
  }
};

export const handlePasswordMatch = (
  value: string,
  valueToMatch: string, message: string
): string | undefined =>
  value !== valueToMatch.trim()
    ? message
    : undefined;
