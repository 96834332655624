import React from 'react';

import { FieldValues, useForm } from 'react-hook-form';

import { Box, TextField, Typography, Button, Alert } from '@mui/material';

import { UserData } from 'api/types/userTypes';
import {
  textFieldWrapper,
  textFieldLabel,
  standardTextField,
  textFieldContainer,
  submitButton,
  submitButtonWrapper,
} from 'styles/inputFieldsStyles';
import { viravaCustom } from 'utils/config/virava';
import { useTranslations } from 'utils/context/TranslationContext';
import { useAuthResponseHandler } from 'utils/hooks/useAuthResponseHandler';
import { ERROR_LABEL, WARNING_LABEL } from 'utils/constants/identifiers';
import { VALIDATION_PASSWORD } from 'utils/constants/inputs';
import { ROUTE_PATH_PROFILE } from 'utils/constants/routes';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { handlePasswordMatch, handleSpacesForRequiredFields } from 'utils/helpers/formFieldsHelper';
import { inputError } from "styles/commonStyles";

interface SecurityProps {
  userData: UserData;
}

interface AuthErrorType {
  detail: string;
  status: number;
}

export const SecurityForm = (props: SecurityProps) => {
  const { userData } = props;

  const { translate } = useTranslations();
  const authResponseHandler = useAuthResponseHandler();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const onSubmit = async (data: FieldValues): Promise<void> => {
    const { currentPassword, newPassword, confirmPassword } = data;

    try {
      await viravaCustom.changePassword(
        userData.email as string,
        currentPassword,
        newPassword,
        confirmPassword
      );
      authResponseHandler.successResultHandle(HttpStatusCodes.Created);
      window.location.href = `${ROUTE_PATH_PROFILE}?tab=profileSettings`;
    } catch (error) {
      authResponseHandler.handleError(error as AuthErrorType);
    }
  };

  return (
    <Box
      sx={textFieldWrapper}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {authResponseHandler.authenticationError && (
        <Alert severity={WARNING_LABEL}>
          {authResponseHandler.authenticationError.detail}
        </Alert>
      )}
      {authResponseHandler.isServerError && (
        <Alert severity={ERROR_LABEL}>
          {translate('messages.noConnectionToTheServerMessage')}
        </Alert>
      )}
      {authResponseHandler.isClientError && (
        <Alert severity={ERROR_LABEL}>
          {translate('messages.unexpectedErrorMessage')}
        </Alert>
      )}
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>
          {translate('labels.password')}
        </Typography>

        <TextField
          sx={{ ...standardTextField, ...inputError }}
          id="currentPassword"
          type="password"
          variant="standard"
          InputLabelProps={{ required: true }}
          {...register('currentPassword', {
            required: translate('messages.requiredFieldMessage'),
            validate: handleSpacesForRequiredFields,
            pattern: {
              value: VALIDATION_PASSWORD,
              message: translate('messages.invalidPasswordMessage'),
            },
          })}
          error={Boolean(errors.currentPassword)}
          helperText={errors.currentPassword && errors.currentPassword.message as string}
        />
      </Box>
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>
          {translate('labels.newPassword')}
        </Typography>

        <TextField
          sx={{ ...standardTextField, ...inputError }}
          id="newPassword"
          type="password"
          variant="standard"
          InputLabelProps={{ required: true }}
          {...register('newPassword', {
            required: translate('messages.requiredFieldMessage'),
            validate: handleSpacesForRequiredFields,
            pattern: {
              value: VALIDATION_PASSWORD,
              message: translate('messages.invalidPasswordMessage'),
            },
          })}
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword?.message as string}
        />
      </Box>
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>
          {translate('labels.confirmNewPassword')}
        </Typography>

        <TextField
          sx={{ ...standardTextField, ...inputError }}
          id="confirmPassword"
          type="password"
          variant="standard"
          InputLabelProps={{ required: true }}
          {...register('confirmPassword', {
            required: translate('messages.requiredFieldMessage'),
            validate: (value: string) =>
              handlePasswordMatch(
                value,
                watch('newPassword'),
                translate('messages.invalidConfirmPasswordMessage')
              ),
          })}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message as string}
        />
      </Box>
      <Box sx={submitButtonWrapper}>
        <Button
          sx={submitButton}
          variant="contained"
          color="primary"
          aria-label={translate('labels.confirm')}
          type="submit"
        >
          {translate('buttons.confirm')}
        </Button>
      </Box>
    </Box>
  );
};
