import React from 'react';

import { Box, Button, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  item,
  closeIcon,
  uploadButton,
  uploadedFileWrapper,
} from 'styles/inputFieldsStyles';
import { useTranslations } from 'utils/context/TranslationContext';

interface UploadedFilesProps {
  isUploaded: boolean;
  myFiles: never[];
  setMyFiles: React.Dispatch<React.SetStateAction<never[]>>;
  setIsUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  file: { [key: string]: string }[];
  uploadFile: Function;
}

export const UploadedFiles = (props: UploadedFilesProps) => {
  const { isUploaded, myFiles, setMyFiles, setIsUploaded, file, uploadFile } =
    props;

  const { translate } = useTranslations();

  const close = () => {
    setMyFiles([]);
    setIsUploaded(false);
  };

  return (
    myFiles[0] && (
      <Box sx={uploadedFileWrapper}>
        <ListItem sx={item} key="file">
          <ListItemText primary={file[0].path} />
          {isUploaded && <CloseIcon sx={closeIcon} onClick={close} />}
        </ListItem>

        <Button
          variant="contained"
          sx={uploadButton}
          onClick={() => uploadFile(file)}
        >
          {translate('buttons.upload')}
        </Button>
      </Box>
    )
  );
};
