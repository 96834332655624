import { nameof } from 'ts-simple-nameof';

import { userServiceClient } from 'api/apiClient';
import {
  Api,
  ReferralLink,
  ReferralLinkResponse,
  ReferralRequest,
} from 'api/types/userTypes';
import { useGetRequest } from 'utils/hooks/query/useGetRequest';
import { useMutationRequest } from 'utils/hooks/query/useMutationRequest';

export const getUserReferralLink = (id: number): ReferralLink => {
  const params = { id };

  const { data: referral } = useGetRequest({
    func: async () => {
      if (!id) {
        return null;
      }
      return (await userServiceClient.api.getUserReferralLink(id)).data;
    },
    key: nameof<Api<unknown>>((x) => x.api.getUserReferralLink(id)),
    params,
  });
  return (referral as ReferralLinkResponse)?.data as ReferralLink;
};

export const submitReferAFriend = (id: number) =>
  useMutationRequest(
    (data: ReferralRequest) =>
      userServiceClient.api.submitReferAFriend(id, data),
    nameof<Api<unknown>>((x) => x.api.submitReferAFriend)
  );
