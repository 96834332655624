import {
  ROUTE_PATH_TRANSACTION_DEPOSIT,
  ROUTE_PATH_TRANSACTION_WITHDRAW,
} from 'utils/constants/routes';

export const headerButtons = [
  {
    path: ROUTE_PATH_TRANSACTION_DEPOSIT,
    title: 'deposit',
  },
  {
    path: ROUTE_PATH_TRANSACTION_WITHDRAW,
    title: 'withdraw',
  },
];
