export const openPdf = (response: Response) => {
  response.blob().then((blob) => {
    // Create a blob.
    const fileURL = URL.createObjectURL(blob);

    // Create anchor element so we can click it.
    const link = document.createElement('a');

    // Attaching url to anchor element.
    link.href = fileURL;

    // Set target to blank.
    link.target = '_blank';

    // Append to html link element page
    document.body.appendChild(link);

    // Simulate link click behind the scenes to start download.
    link.click();

    // Removing the created link.
    link.remove();
  });
};
