import { nameof } from 'ts-simple-nameof';

import { userServiceClient } from 'api/apiClient';
import {
  Api,
  ErrorResponse,
  HttpResponse,
  IdentityDocument,
  IdentityDocumentResponse,
  ShuftiReferenceNumber,
  ShuftiVerificationURLData,
} from 'api/types/userTypes';
import { useMutationRequest } from 'utils/hooks/query/useMutationRequest';
import { useGetRequest } from 'utils/hooks/query/useGetRequest';

export const getUserShuftiVerificationUrl = (id: number) =>
  useMutationRequest(
    (request: ShuftiVerificationURLData) =>
      userServiceClient.api.getUserShuftiVerificationUrl(id, request),
    nameof<Api<unknown>>((x) => x.api.getUserShuftiVerificationUrl)
  );

export const putUserIdentityDocument = (id: number) =>
  useMutationRequest(
    (request: ShuftiReferenceNumber) => {
      if (
        id &&
        localStorage.getItem('shuftiReference') &&
        localStorage.getItem('shuftiReference') !== 'mock'
      ) {
        return userServiceClient.api.putUserIdentityDocument(id, request);
      }
      return null as unknown as Promise<
        HttpResponse<IdentityDocumentResponse, ErrorResponse>
      >;
    },
    nameof<Api<unknown>>((x) => x.api.putUserIdentityDocument)
  );

export const getUserIdentityDocument = (id: number): IdentityDocument => {
  const params = { id };

  const { data: idDocument } = useGetRequest({
    func: async () => {
      if (!id) {
        return null;
      }
      return (await userServiceClient.api.getUserIdentityDocument(id)).data;
    },
    key: nameof<Api<unknown>>((x) => x.api.getUserIdentityDocument),
    params,
  });
  return (idDocument as IdentityDocumentResponse)?.data as IdentityDocument;
};
