import React, { Dispatch } from 'react';

import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';

import { Box, TextField, Typography, CircularProgress } from '@mui/material';

import {
  getUserProfile,
  patchUserProfile,
} from 'api/controllers/UserController';
import { UserProfile } from 'api/types/userTypes';
import {
  textFieldWrapper,
  textFieldContainer,
  textFieldLabel,
  standardTextField,
} from 'styles/inputFieldsStyles';
import { VALIDATION_NICKNAME, VALIDATION_PHONE } from 'utils/constants/inputs';
import { TextFieldComponent } from 'components/common/inputFields/TextFieldComponent';
import { useTranslations } from 'utils/context/TranslationContext';
import { formatDate } from 'utils/helpers/formatDateHelper';
import {
  VALUE_MAX_NIKCNAME,
  VALUE_MIN_NIKCNAME,
  VALUE_MAX_PHONE,
  VALUE_MIN_PHONE,
} from 'utils/constants/options';

interface ProfileSettingsProps {
  userId: number;
}

export const ProfileSettingsForm = (props: ProfileSettingsProps) => {
  const { userId } = props;

  const { translate } = useTranslations();

  const updateUserProfile = patchUserProfile(userId);

  const userSettings = getUserProfile(userId) as UserProfile;

  const handleUpdateUserProfile = (
    fieldName: string,
    fieldValue: string,
    setIsEditing: Dispatch<React.SetStateAction<boolean>>
  ): UseFormHandleSubmit<FieldValues> | void => {
    updateUserProfile.mutateAsync({ [fieldName]: fieldValue });
    setIsEditing(false);
  };

  return userSettings ? (
    <Box sx={textFieldWrapper}>
      <TextFieldComponent
        id="nickname"
        type="text"
        variant="standard"
        required
        label={translate('labels.nickname')}
        fieldValue={userSettings.nickname}
        handleDone={handleUpdateUserProfile}
        patternValue={VALIDATION_NICKNAME}
        patternMessage={translate('labels.nicknameValidation')}
        minValue={VALUE_MIN_NIKCNAME}
        maxValue={VALUE_MAX_NIKCNAME}
      />
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>{translate('labels.email')}</Typography>

        <TextField
          sx={standardTextField}
          id="email"
          variant="standard"
          type="text"
          defaultValue={userSettings.email}
          disabled
        />
      </Box>
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>{translate('labels.name')}</Typography>

        <TextField
          sx={standardTextField}
          id="name"
          variant="standard"
          type="text"
          defaultValue={userSettings.name}
          disabled
        />
      </Box>
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>
          {translate('labels.dateOfBirth')}
        </Typography>

        <TextField
          sx={standardTextField}
          id="dateOfBirth"
          variant="standard"
          type="text"
          defaultValue={formatDate(userSettings.dateOfBirth as string)}
          disabled
        />
      </Box>
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>
          {translate('labels.citizenship')}
        </Typography>

        <TextField
          sx={standardTextField}
          id="citizenship"
          variant="standard"
          type="text"
          defaultValue={userSettings.citizenship}
          disabled
        />
      </Box>
      <Box sx={textFieldContainer}>
        <Typography sx={textFieldLabel}>
          {translate('labels.address')}
        </Typography>

        <TextField
          sx={standardTextField}
          id="address"
          variant="standard"
          type="text"
          defaultValue={userSettings.address}
          disabled
        />
      </Box>
      <TextFieldComponent
        id="phone"
        type="text"
        variant="standard"
        required
        label={translate('labels.phone')}
        fieldValue={userSettings.phone}
        handleDone={handleUpdateUserProfile}
        patternValue={VALIDATION_PHONE}
        patternMessage={translate('labels.phoneValidation')}
        minValue={VALUE_MIN_PHONE}
        maxValue={VALUE_MAX_PHONE}
      />
    </Box>
  ) : (
    <CircularProgress color="primary" />
  );
};
