import React from 'react';

import { Box, Button, Card, TextField, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { showSuccessToast } from 'utils/helpers//toastHelper';
import { socialMediaIcons } from 'utils/constants/socialMediaIcons';
import { useTranslations } from 'utils/context/TranslationContext';
import { button } from 'styles/verificationMessage';
import { ReferralLink } from 'api/types/userTypes';
import {
  bodyCardWrapper,
  referralButton,
  headerSocialMediaCardSection,
  referralCardTitle,
  referralCard,
  referralLink,
  shareIconContainer,
  socialMediaIconsWrapper,
  socialMediaCardBackground,
  socialMediaButtonMТ,
} from 'styles/referFriendStyles';

export const SocialMediaCard = ({ link }: ReferralLink) => {
  const { translate } = useTranslations();

  const onCopy = () => {
    showSuccessToast(translate('messages.successfullyCopied'));
  };

  return (
    <Card elevation={4} sx={{ ...referralCard, ...socialMediaCardBackground }}>
      <Box sx={headerSocialMediaCardSection}>
        <Box sx={shareIconContainer}>
          <ShareIcon sx={{ fontSize: '24px' }} />
        </Box>
        <Box ml="8px">
          <Typography variant="body1" sx={referralCardTitle}>
            {translate('labels.shareALink')}
          </Typography>
          <Typography variant="body1">
            {translate('labels.socialMediaShare')}
          </Typography>
        </Box>
      </Box>
      <Box sx={bodyCardWrapper}>
        <Box sx={socialMediaIconsWrapper}>
          {socialMediaIcons?.map((icon) => (
            <Box
              component="img"
              alt={icon.label}
              key={icon.label}
              src={icon.imageSrc}
              width="40px"
            />
          ))}
        </Box>
        <Typography variant="body1">
          {translate('labels.copyPasteLinkDescription')}
        </Typography>
        <TextField
          sx={referralLink}
          variant="standard"
          type="text"
          disabled
          value={link}
        />
        <CopyToClipboard text={link || ''} onCopy={onCopy}>
          <Button
            sx={{ ...button, ...referralButton, ...socialMediaButtonMТ }}
            variant="contained"
            aria-label={translate('buttons.copyLink')}
          >
            {translate('buttons.copyLink')}
          </Button>
        </CopyToClipboard>
      </Box>
    </Card>
  );
};
