import { useState } from 'react';

import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { StatusCodeRanges } from 'utils/enums/StatusCodeRanges';

interface AuthErrorType {
  detail: string;
  status: number;
}


export const useAuthResponseHandler = () => {
  const [authenticationError, setAuthenticationError] = useState<AuthErrorType>();
  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [isClientError, setIsClientError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleError = (error: AuthErrorType): void => {
    switch (error.status) {
      case HttpStatusCodes.BadRequest:
        setAuthenticationError(error);
        break;
      case HttpStatusCodes.Unauthorized:
        setAuthenticationError(error);
        break;
      case HttpStatusCodes.InternalServerError:
        setIsServerError(true);
        break;
      default:
        setIsClientError(true);
        break;
    }
  };

  const successResultHandle = (statusCode: number): void => {
    if (
      statusCode >= StatusCodeRanges.LowestSuccessfull ||
      statusCode <= StatusCodeRanges.HighestSuccessfull
    ) {
      setIsSuccess(true);
    }
  };

  return {
    successResultHandle,
    handleError,
    isSuccess,
    authenticationError,
    isServerError,
    isClientError,
  };
};
