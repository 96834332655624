import React from 'react';

import { Box, AppBar, Link } from '@mui/material';

import IuvoLogoBlack from 'assets/images/iuvo-logo-black.svg';
import { MainWrapper } from 'components/common/MainWrapper';
import { NavigationItems } from 'components/common/mainNavigation/NavigationItems';
import { LanguageMenu } from 'components/common/i18n/LanguageMenu';
import { IUVO_PLATFORM_PATH } from 'utils/constants/mainNavigation';
import { useTranslations } from 'utils/context/TranslationContext';
import {
  appBar,
  linksContainer,
  logoWrapper,
  logoImage,
} from 'styles/mainNavigationStyles';
import { mainWrapperCommon } from 'styles/mainWrapperStyles';

export const MainNavigation = () => {
  const { translate } = useTranslations();

  return (
    <AppBar sx={appBar}>
      <MainWrapper styles={mainWrapperCommon}>
        <Link sx={logoWrapper} href={IUVO_PLATFORM_PATH}>
          <img
            style={{ ...logoImage }}
            src={IuvoLogoBlack}
            alt={translate('labels.logoLabel')}
          />
        </Link>

        <Box sx={linksContainer}>
          <NavigationItems />
          <LanguageMenu />
        </Box>
      </MainWrapper>
    </AppBar>
  );
};
