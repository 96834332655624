import { DATE_FORMAT } from 'utils/constants/inputs';

export const formatDate = (date: string): string => {
  if (date) {
    const datePart = date.match(DATE_FORMAT);

    if (datePart) {
      const year = datePart[0];
      const month = datePart[1];
      const day = datePart[2];

      return `${day}.${month}.${year}`;
    }
  }

  return date;
};
