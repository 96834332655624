import React, { useState } from 'react';

import { Box, Drawer, IconButton, Link, List, Tooltip } from '@mui/material';
import { Menu, Close } from '@mui/icons-material';

import { headerButtons } from 'utils/data/headerButtons';
import { useTranslations } from 'utils/context/TranslationContext';

import { NavigationItems } from 'components/common/mainNavigation/NavigationItems';

import {
  closeButton,
  headerButton,
  iconButton,
  list,
  link,
  navigationWrapper,
} from 'styles/headerStyles';
import { textTransformCapitalize } from 'styles/commonStyles';

export const HeaderDrawerNavigation = () => {
  const { translate } = useTranslations();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Tooltip title={translate('labels.navigation')}>
        <IconButton
          sx={{ ...headerButton, ...iconButton }}
          aria-label={translate('labels.navigation')}
          size="large"
          onClick={handleDrawerToggle}
        >
          <Menu />
        </IconButton>
      </Tooltip>

      <Box component="nav" sx={navigationWrapper}>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box onClick={handleDrawerToggle}>
            <IconButton
              sx={{ ...closeButton, ...iconButton }}
              aria-label={translate('labels.closeNavigation')}
              size="large"
              onClick={handleDrawerToggle}
            >
              <Close />
            </IconButton>
            <List sx={list}>
              <NavigationItems />

              {headerButtons.map(({ path, title }) => (
                <Link
                  key={title}
                  href={path}
                  underline="none"
                  sx={{
                    ...link,
                    ...textTransformCapitalize,
                  }}
                >
                  {translate(`navigation.${title}`)}
                </Link>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};
