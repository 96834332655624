import {
  UseQueryResult,
  useQuery,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
} from 'react-query';

export interface GetRequestProps<TResult> {
  func: QueryFunction<TResult>;
  key: string;
  params?: object | number | string | [];
  options?: Omit<
    UseQueryOptions<TResult, unknown, TResult, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export const useGetRequest = <TResult>(
  request: any
): UseQueryResult<TResult> => {
  let key: Array<string | object | number> = [request.key];
  if (request.params) {
    if (
      typeof request.params !== 'object' ||
      Object.keys(request.params).filter((x) => request.params![x]).length > 0
    ) {
      key = [...key, request.params];
    }
  }

  return useQuery<TResult>(key, request.func, request.options);
};
