import React from 'react';

import { PageTitle } from 'components/common/PageTitle';
import { useTranslations } from 'utils/context/TranslationContext';

export const NotificationsScreen = () => {
  const { translate } = useTranslations();

  return <PageTitle title={translate('navigation.notifications')} />;
};
