export const avatarViewWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '16px',
};

export const avatarView = {
  width: '100px',
  height: '100px',
  backgroundColor: 'primary.main',
};

export const avatarOptionsWrapper = {
  boxShadow: '0px 30px 45px rgba(0, 0, 0, 0.1)',
  padding: { sm: '40px 40px 0', xs: '40px 0 0' },
};

export const avatarOptionsContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: { sm: '32px', xs: '16px' },
  justifyContent: 'center',
};

export const chosenAvatar = {
  borderColor: 'primary.main',
};

export const avatarOption = {
  width: { sm: '100px', xs: '70px' },
  height: { sm: '100px', xs: '70px' },
  borderRadius: 0,
  border: '2px solid',
  borderColor: 'transparent',
  cursor: 'pointer',
};

export const buttonsContainer = {
  display: 'flex',
  justifyContent: 'center',
  gap: { md: '40px', xs: '24px' },
  padding: '40px',
};

export const button = {
  width: '100%',
  maxWidth: '140px',
  height: { xs: '32px', sm: '40px' },
  padding: '16px 32px',
  fontSize: '16px',
};
