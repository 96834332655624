import React, { useState, FocusEvent } from 'react';

import { FieldErrors, FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import {
  Box,
  Button,
  MobileStepper,
  TextField,
  Typography,
} from '@mui/material';

import { useTranslations } from 'utils/context/TranslationContext';
import { PreFilledQuestionResponse, UserAnswer } from 'api/types/userTypes';
import {
  mobileStepper,
  mobileStepperButtons,
  mobileStepperWrapper,
  textField,
  inputFieldWrapper,
} from 'styles/verificationStyles';
import { CheckboxComponent } from 'components/common/inputFields/CheckboxComponent';
import { inputError } from "styles/commonStyles";
import { QuestionDropdown } from './QuestionDropdown';

interface ResponsiveSmallQuestionnaireProps {
  smallQuestionnaireData: PreFilledQuestionResponse[];
  userAnswers: UserAnswer[];
  addAnswer: (answer: UserAnswer) => void;
  deleteAnswer: (answer: UserAnswer) => void;
  onTextFieldBlur: (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    question: PreFilledQuestionResponse
  ) => void;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

export const ResponsiveSmallQuestionnaireForm = (
  props: ResponsiveSmallQuestionnaireProps
) => {
  const { translate } = useTranslations();

  const {
    smallQuestionnaireData,
    userAnswers,
    addAnswer,
    deleteAnswer,
    onTextFieldBlur,
    register,
    setValue,
    clearErrors,
    errors
  } = props;

  const [activeStep, setActiveStep] = useState(0);

  const isLastElement = smallQuestionnaireData.length - 1 === activeStep;

  const handleNext = () => {
    if (userAnswers[activeStep] && userAnswers[activeStep].answerId) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Box>
        {smallQuestionnaireData.map(
          (question: PreFilledQuestionResponse, index) =>
            question.dataType ? (
              <Box
                key={question.id}
                sx={{
                  ...inputFieldWrapper,
                  display: activeStep === index ? 'block' : 'none',
                }}
              >
                <Typography sx={textField}>{question.content} *</Typography>

                <TextField
                  sx={{ ...textField, ...inputError }}
                  id={question.content}
                  variant="standard"
                  type="text"
                  {...register(`question${question.id}`, {
                    required: translate('messages.requiredFieldMessage')
                  })}
                  helperText={errors[`question${question.id}`]?.message as string}
                  error={!!errors[`question${question.id}`]?.message}
                  defaultValue={question.userAnswerContent as string}
                  disabled={!!question.userAnswerContent}
                  onBlur={(e) => onTextFieldBlur(e, question)}
                />
              </Box>
            ) : (
              <Box
                sx={{ display: activeStep === index ? 'block' : 'none' }}
                key={question.id}
              >
                <QuestionDropdown
                  addAnswer={addAnswer}
                  deleteAnswer={deleteAnswer}
                  question={question}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  errors={errors}
                />
              </Box>
            )
        )}

        {isLastElement && (
          <CheckboxComponent
            label={translate('labels.smallQuestionnaireCheckbox')}
            required
            register={register}
            setValue={setValue}
            errors={errors}
          />
        )}
      </Box>

      <Box sx={mobileStepperWrapper}>
        <MobileStepper
          variant="progress"
          position="static"
          steps={smallQuestionnaireData.length}
          activeStep={activeStep}
          sx={mobileStepper}
          nextButton={
            isLastElement ? (
              <Button
                sx={mobileStepperButtons}
                variant="contained"
                color="primary"
                aria-label={translate('labels.confirm')}
                type="submit"
              >
                {translate('buttons.confirm')}
              </Button>
            ) : (
              <Button
                sx={mobileStepperButtons}
                variant="contained"
                color="primary"
                type="submit"
                aria-label={translate('buttons.next')}
                onClick={handleNext}
                disabled={isLastElement}
              >
                {translate('buttons.next')}
              </Button>
            )
          }
          backButton={
            <Button
              sx={mobileStepperButtons}
              variant="contained"
              color="primary"
              aria-label={translate('buttons.back')}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {translate('buttons.back')}
            </Button>
          }
        />
      </Box>
    </>
  );
};
