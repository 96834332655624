export const languageButton = {
  height: '38px',
  padding: '8px',
  color: 'text.primary',
  fontSize: '16px',
  textTransform: 'uppercase',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  boxShadow: 'none',
};

export const headerVersion = {
  height: '40px',
  color: 'text.secondary',
  backgroundColor: 'rgba(200, 200, 200, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(200, 200, 200, 0.4)',
  },
};

export const lang = {
  textTransform: 'capitalize',
};
