import React, { FocusEvent } from 'react';

import { FieldErrors, FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Box, Button, TextField, Typography } from '@mui/material';

import { PreFilledQuestionResponse, UserAnswer } from 'api/types/userTypes';
import { CheckboxComponent } from 'components/common/inputFields/CheckboxComponent';
import { submitButton, submitButtonWrapper } from 'styles/inputFieldsStyles';
import {
  inputWrapper,
  textField,
  inputFieldWrapper,
} from 'styles/verificationStyles';
import { useTranslations } from 'utils/context/TranslationContext';

import { inputError } from "styles/commonStyles";
import { QuestionDropdown } from './QuestionDropdown';

interface SmallQuestionnaireProps {
  smallQuestionnaireData: PreFilledQuestionResponse[];
  addAnswer: (answer: UserAnswer) => void;
  deleteAnswer: (answer: UserAnswer) => void;
  onTextFieldBlur: (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    question: PreFilledQuestionResponse
  ) => void;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

export const SmallQuestionnaireForm = (props: SmallQuestionnaireProps) => {
  const { translate } = useTranslations();

  const {
    smallQuestionnaireData,
    addAnswer,
    deleteAnswer,
    onTextFieldBlur,
    register,
    setValue,
    errors,
    clearErrors
  } = props;

  return (
    <Box>
      <Box sx={inputWrapper}>
        {smallQuestionnaireData &&
          smallQuestionnaireData.map((question: PreFilledQuestionResponse) =>
            question.dataType ? (
              <Box sx={inputFieldWrapper} key={question.id}>
                <Typography sx={textField}>{question.content} *</Typography>

                <TextField
                  sx={{ ...textField, ...inputError }}
                  id={question.content}
                  variant="standard"
                  type="text"
                  {...register(`question${question.id}`, {
                    required: translate('messages.requiredFieldMessage')
                  })}
                  helperText={errors[`question${question.id}`]?.message as string}
                  error={!!errors[`question${question.id}`]?.message}
                  defaultValue={question.userAnswerContent as string}
                  disabled={!!question.userAnswerContent}
                  onBlur={(e) => onTextFieldBlur(e, question)}
                />
              </Box>
            ) : (
              <QuestionDropdown
                addAnswer={addAnswer}
                deleteAnswer={deleteAnswer}
                key={question.id}
                question={question}
                register={register}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
              />
            )
          )}
      </Box>
      <Box sx={submitButtonWrapper}>
        <CheckboxComponent
          label={translate('labels.smallQuestionnaireCheckbox')}
          required
          register={register}
          setValue={setValue}
          errors={errors}
        />

        <Button
          sx={submitButton}
          variant="contained"
          color="primary"
          aria-label={translate('labels.confirm')}
          type="submit"
        >
          {translate('buttons.confirm')}
        </Button>
      </Box>
    </Box>
  );
};
