import React, { useState } from 'react';

import { Avatar } from '@mui/material';

import { blobToFileHelper } from 'utils/helpers/blobToFileHelper';
import { getDefaultAvatarById } from 'api/controllers/AvatarController';
import { avatarOption } from 'styles/avatarStyles';

interface AvatarWrapperProps {
  id: number;
  style: any;
  applyAvatarStyling: Function;
}

export const AvatarWrapper = (props: AvatarWrapperProps) => {
  const { id, style, applyAvatarStyling } = props;

  const [url, setUrl] = useState('');

  const img = getDefaultAvatarById(id);

  if (img) {
    blobToFileHelper(img, setUrl);
  }

  return (
    <Avatar
      sx={{
        ...avatarOption,
        ...style,
      }}
      alt={`avatarImage${id}`}
      src={url}
      onClick={() => applyAvatarStyling(id, img)}
    />
  );
};
