import React, { useState } from 'react';

import { Language } from '@mui/icons-material';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';

import { useTranslations } from 'utils/context/TranslationContext';
import { LANGUAGE_CONFIG } from 'utils/constants/context/i18n';
import { profileMenu } from 'styles/headerStyles';
import { languageButton, lang, headerVersion } from 'styles/languageMenuStyles';
import { anchorOrigin, transformOrigin } from 'styles/commonStyles';

interface LanguageMenuProps {
  isHeaderVersion?: boolean;
}

export const LanguageMenu = (props: LanguageMenuProps) => {
  const { currentLanguage, translate, setCurrentLanguage } = useTranslations();

  const { isHeaderVersion = false } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={translate('labels.languageMenu')}>
        <Button
          sx={
            isHeaderVersion
              ? { ...languageButton, ...headerVersion }
              : languageButton
          }
          aria-label={translate('labels.languageMenu')}
          size="medium"
          onClick={openLanguageMenu}
          aria-controls={translate('labels.languageMenu')}
          aria-haspopup="true"
          aria-expanded={open}
        >
          <Language />
          {currentLanguage}
        </Button>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeLanguageMenu}
        onClick={closeLanguageMenu}
        PaperProps={{
          elevation: 0,
          sx: profileMenu,
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {LANGUAGE_CONFIG.languages.map(({ language, code }) => (
          <MenuItem
            key={code}
            sx={lang}
            onClick={() => setCurrentLanguage(code)}
            selected={language === code}
          >
            {language}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
