/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** This is the object holding the user's register data */
export interface UserRegisterData {
  /** @format int64 */
  userId?: number;
}

/** This is the user register response object */
export interface RegisterResponse {
  /** This is the object holding the user's register data */
  data?: UserRegisterData;
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the user's login data */
export type UserLogin = UserData;

/** This is the login response */
export interface LoginResponse {
  /** This is the object holding the user's data */
  data?: UserLoginData;
  /** This is the error object */
  error?: Error;
}

/** This is the object holding the user's data */
export interface UserLoginData {
  /** This is the object holding the user's login data */
  user?: UserLogin;
  permissions?: Permission[];
}

/** This is the permission object */
export interface Permission {
  /** This is the resource object */
  resource?: Resource;
  /** This is the scope holder object */
  scopeHolder?: ScopeHolder;
}

/** This is the resource object */
export interface Resource {
  resource?: string;
}

/** This is the scope holder object */
export interface ScopeHolder {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
}

/** Body for the referral request. */
export interface ReferralRequest {
  /** @format email */
  email?: string;
  message?: string;
  language?: string;
}

/** The response which holds the information about the referral link of a given user. */
export interface ReferralLinkResponse {
  /** Body for the referral link request. */
  data?: ReferralLink;
  /** This is the error object */
  error?: Error;
}

/** Body for the referral link request. */
export interface ReferralLink {
  /** @format uri */
  link?: string;
}

/** The response object for all documents requested from the backoffice for a specified user */
export interface RequestedDocumentsResponse {
  /** Object for the requested documents. */
  data?: RequestedDocuments;
  /** This is the error object */
  error?: Error;
}

/** Object for the requested documents. */
export type RequestedDocuments = RequestedDocument[];

/** Object for a requested document. */
export interface RequestedDocument {
  title?: string;
  documentType?: DocumentType;
  details?: string;
  isForm?: boolean;
  documentName?: string;
}

/** Response for user preferred notifications */
export interface NotificationSettingsResponse {
  data?: NotificationSettings;
  /** This is the error object */
  error?: Error;
}

export interface NotificationSettings {
  marketingInfo?: boolean;
  newsUpdates?: boolean;
  thirdPartyMarketingInfo?: boolean;
}

/** Response for bank details */
export interface BankDetailsResponse {
  /** Object for bank details */
  data?: BankDetails;
  /** This is the error object */
  error?: Error;
}

/** Object for bank details */
export interface BankDetails {
  bankName?: string;
  beneficiaryName?: string;
  accountNumber?: string;
  registrationNumber?: string;
  swift?: string;
  bankAddress?: string;
  country?: string;
  details?: string;
}

/** Response for users bank accounts */
export interface BankAccountsResponse {
  /** Object holding the users default and other bank accounts. */
  data?: BankAccounts;
  /** This is the error object */
  error?: Error;
}

/** Object holding the users default and other bank accounts. */
export interface BankAccounts {
  default?: BankAccount[];
  other?: BankAccount[];
}

/** Object for an users bank account */
export interface BankAccount {
  IBAN?: string;
  currency?: string;
  /** @format int32 */
  order?: number;
}

/** Response for users uploaded documents */
export interface DocumentsHistoryResponse {
  /** Object for users uploaded documents */
  data?: DocumentsHistory;
  /** This is the error object */
  error?: Error;
}

/** Object for users uploaded documents */
export type DocumentsHistory = Document[];

/** Object for a user document */
export interface Document {
  /** @format int64 */
  id?: number;
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  /** @format date */
  date?: string;
  status?: 'IN_PROCESS' | 'CONFIRMED' | 'DECLINED';
}

/** Response for basic user information shown on every screen */
export interface UserDataResponse {
  /** Object holding basic user information shown in every screen */
  data?: UserData;
  /** This is the error object */
  error?: Error;
}

/** Object holding basic user information shown in every screen */
export interface UserData {
  /** @format int64 */
  userId?: number;
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  email?: string;
  investmentStatus?: 'LEAD' | 'SILVER' | 'GOLD';
  progress?: number;
  /** @format int64 */
  avatarId?: number;
  userStatus?: UserStatus;
  /** Object for different user balances */
  funds?: Funds;
  requiredActions?: Action[];
  /** This is the user permissions response object */
  permissions?: UserPermissions;
}

/** This is the user permissions response object */
export interface UserPermissions {
  deposit?: boolean;
  withdraw?: boolean;
  use_pos?: boolean;
  invest?: boolean;
}

export type UserStatus = 'ONBOARDING' | 'NORMAL' | 'RESTRICTED' | 'LOCKED';

/** Object for different user balances */
export type Funds = Balance[];

export interface Balance {
  /** @format int64 */
  currencyId?: number;
  amount?: number;
  parsedAmount?: string;
}

export interface Action {
  /** @format int64 */
  id?: number;
  type?: ActionType;
  description?: string;
  priority?: number;
  isDocument?: boolean;
}

export type ActionType =
  | 'TRIGGER_ID_VERIFICATION'
  | 'TRIGGER_AML_CHECK'
  | 'FILL_SMALL_QUESTIONNAIRE'
  | 'FILL_COMPANY_QUESTIONNAIRE'
  | 'REQUEST_PROOF_OF_ADDRESS'
  | 'FILL_BIG_QUESTIONNAIRE'
  | 'FILL_COMPANY_DETAILS'
  | 'REQUEST_DECLARATION_OF_FUNDS'
  | 'FILL_PEP_DECLARATION'
  | 'REQUEST_OTHER_DOCUMENT';

export type DocumentType =
  | 'REQUEST_PROOF_OF_ADDRESS'
  | 'FILL_BIG_QUESTIONNAIRE'
  | 'REQUEST_DECLARATION_OF_FUNDS'
  | 'FILL_PEP_DECLARATION'
  | 'REQUEST_OTHER_DOCUMENT';

/** This is the object holding the user's email */
export interface UserEmail {
  /** @pattern ^[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$ */
  email?: string;
}

/** This is the object holding the platform users's profile data */
export interface UserProfilePatch {
  /**
   * @maxLength 20
   * @pattern ^[A-Za-z0-9]+[_A-Za-z0-9]*
   */
  nickname?: string;
  /**
   * @minLength 8
   * @maxLength 15
   * @pattern ^\+?\d{8,15}$
   */
  phone?: string;
}

/** This is the object holding the platform users's profile data */
export type UserProfile = (UserProfilePatch & UserEmail) & {
  /** @pattern ^.*[^ ].*$ */
  name?: string;
  address?: string;
  /** @format date */
  dateOfBirth?: string;
  citizenship?: string;
};

/** This is the object holding the platform users's company details */
export interface CompanyDetailsPatch {
  idNumber?: string;
  companyName?: string;
  /**
   * @format int64
   * @min 1
   */
  countryId?: number;
  representativePerson?: string;
  phone?: string;
}

/** This is the object holding the platform users's company details */
export interface CompanyDetails {
  /** @format int64 */
  id?: number;
  idNumber?: string;
  companyName?: string;
  /**
   * @format int64
   * @min 1
   */
  countryId?: number;
  representativePerson?: string;
  phone?: string;
}

/** This is the object holding the data needed for generating a Shufti verification URL. */
export interface ShuftiVerificationURLData {
  /** A URL to redirect after completing the verification. */
  redirectUrl: string;
}

/** This is the object holding the URL for starting a Shufti verification. */
export interface ShuftiVerificationURL {
  /** Reference number for the current verification process. */
  reference?: string;
  /** URL for start of Shufti verification. */
  verificationUrl?: string;
}

/** This is the object holding the reference number received after completing the Shufti verification. */
export interface ShuftiReferenceNumber {
  /** Shufti verification reference number */
  referenceNumber?: string;
}

/** This is the object holding the platform users's id document */
export interface IdentityDocument {
  documentType?: string;
  /** @format date */
  expirationDate?: string;
  idNumber?: string;
}

/** This is the object holding the platform users's id document expiration status */
export interface ExpirationStatus {
  isExpired?: boolean;
}

/** This is the object holding an image */
export interface Avatar {
  /**
   * @format int64
   * @min 1
   */
  id?: number;
}

/** This is the upload avatar response */
export interface UploadAvatarResponse {
  /** This is the object holding an image */
  data?: Avatar;
  /** This is the error object */
  error?: Error;
}

/** Avatars response */
export interface MultiAvatarResponse {
  /** This is the object holding all avatar ids */
  data?: AvatarIds;
  /** This is the error object */
  error?: Error;
}

/** This is the object holding all avatar ids */
export interface AvatarIds {
  avatarIds?: number[];
}

/** This is the response of Shufti verification URL */
export interface ShuftiVerificationURLResponse {
  /** This is the object holding the URL for starting a Shufti verification. */
  data?: ShuftiVerificationURL;
  /** This is the error object */
  error?: Error;
}

/** This is the id document response */
export interface IdentityDocumentResponse {
  /** This is the object holding the platform users's id document */
  data?: IdentityDocument;
  /** This is the error object */
  error?: Error;
}

/** This is the id document expiration status response */
export interface ExpirationStatusResponse {
  /** This is the object holding the platform users's id document expiration status */
  data?: ExpirationStatus;
  /** This is the error object */
  error?: Error;
}

/** This is the user profile response */
export interface UserProfileResponse {
  /** This is the object holding the platform users's profile data */
  data?: UserProfile;
  /** This is the error object */
  error?: Error;
}

/** This is the company details response */
export interface CompanyDetailsResponse {
  /** This is the object holding the platform users's company details */
  data?: CompanyDetails;
  /** This is the error object */
  error?: Error;
}

/** This is the single prefilled form response object */
export interface SinglePreFilledFormResponse {
  /** This is the prefilled form response object */
  data?: PrefilledFormResponse;
  /** This is the response object in case of errors, compliant with RFC7807 */
  error?: ErrorResponse;
}

/** This is the prefilled form response object */
export interface PrefilledFormResponse {
  name?: string;
  displayName?: string;
  version?: string;
  questions?: PreFilledQuestionResponse[];
}

/** This is the prefilled question response object */
export interface PreFilledQuestionResponse {
  /** @format int64 */
  id?: number;
  content?: string;
  /** @format int64 */
  userAnswerId?: number;
  userAnswerContent?: string;
  answers?: AnswerResponse[];
  dataType?: string;
}

/** This is the answer response object */
export interface AnswerResponse {
  /** @format int64 */
  id?: number;
  content?: string;
  additionalQuestionContent?: string;
}

/** This is the form result object */
export interface Result {
  formName: string;
  /** @minItems 1 */
  userAnswers: UserAnswer[];
}

/** This is the user answer object */
export interface UserAnswer {
  content?: string;
  /**
   * @format int64
   * @min 1
   */
  questionId: number;
  /**
   * @format int64
   * @min 1
   */
  answerId?: number;
  additionalQuestionAnswer?: string;
  dataType?: string;
}

/** This is the response object in case of errors, compliant with RFC7807 */
export interface ErrorResponse {
  /** This is the error object */
  error?: Error;
}

/** This is the error object */
export interface Error {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://localhost:8080/';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title User Service
 * @version 8.7.0
 * @baseUrl http://localhost:8080/
 *
 * This is the OpenAPI YAML configuration for the User Microservice
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Test endpoint for generating a PDF file by uploading a HTML template. Works only on dev.
     *
     * @tags Template
     * @name GeneratePdfFromHtmlTemplate
     * @request POST:/api/html-template
     * @secure
     */
    generatePdfFromHtmlTemplate: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<string, ErrorResponse>({
        path: `/api/html-template`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description This is a user registration method
     *
     * @tags User
     * @name Register
     * @request POST:/api/register
     * @secure
     */
    register: (params: RequestParams = {}) =>
      this.request<RegisterResponse, ErrorResponse>({
        path: `/api/register`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a user
     *
     * @tags User
     * @name Login
     * @request GET:/api/login
     * @secure
     */
    login: (params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse>({
        path: `/api/login`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Basic user information that will be shown on every screen.
     *
     * @tags User
     * @name GetUserData
     * @request GET:/api/users/{id}/data
     * @secure
     */
    getUserData: (id: number, params: RequestParams = {}) =>
      this.request<UserDataResponse, ErrorResponse>({
        path: `/api/users/${id}/data`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning the profile of a user
     *
     * @tags User
     * @name GetUserProfile
     * @request GET:/api/users/{id}/profile
     * @secure
     */
    getUserProfile: (id: number, params: RequestParams = {}) =>
      this.request<UserProfileResponse, ErrorResponse>({
        path: `/api/users/${id}/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method patching the profile of a user
     *
     * @tags User
     * @name PatchUserProfile
     * @request PATCH:/api/users/{id}/profile
     * @secure
     */
    patchUserProfile: (id: number, data: UserProfilePatch, params: RequestParams = {}) =>
      this.request<UserProfileResponse, ErrorResponse>({
        path: `/api/users/${id}/profile`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method setting user's email
     *
     * @tags User
     * @name CreateUserEmail
     * @request POST:/api/users/{id}/email
     * @secure
     */
    createUserEmail: (id: number, data: UserEmail, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/users/${id}/email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method returning the company details of a user
     *
     * @tags User
     * @name GetCompanyDetails
     * @request GET:/api/users/{id}/company-details
     * @secure
     */
    getCompanyDetails: (id: number, params: RequestParams = {}) =>
      this.request<CompanyDetailsResponse, ErrorResponse>({
        path: `/api/users/${id}/company-details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method patching the company details of a user
     *
     * @tags User
     * @name PatchCompanyDetails
     * @request PATCH:/api/users/{id}/company-details
     * @secure
     */
    patchCompanyDetails: (id: number, data: CompanyDetailsPatch, params: RequestParams = {}) =>
      this.request<CompanyDetailsResponse, ErrorResponse>({
        path: `/api/users/${id}/company-details`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method uploading avatar image
     *
     * @tags User
     * @name UpdateAvatar
     * @request PUT:/api/users/{id}/avatar
     * @secure
     */
    updateAvatar: (
      id: number,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<UploadAvatarResponse, ErrorResponse>({
        path: `/api/users/${id}/avatar`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning all profile avatars
     *
     * @tags Avatar
     * @name GetAvatars
     * @request GET:/api/avatars
     * @secure
     */
    getAvatars: (params: RequestParams = {}) =>
      this.request<MultiAvatarResponse, ErrorResponse>({
        path: `/api/avatars`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning a single user avatar
     *
     * @tags Avatar
     * @name GetUserAvatarById
     * @request GET:/api/user-avatars/{id}
     * @secure
     */
    getUserAvatarById: (id: number, params: RequestParams = {}) =>
      this.request<File, ErrorResponse>({
        path: `/api/user-avatars/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description This is a method returning a single default avatar
     *
     * @tags Avatar
     * @name GetDefaultAvatarById
     * @request GET:/api/default-avatars/{id}
     * @secure
     */
    getDefaultAvatarById: (id: number, params: RequestParams = {}) =>
      this.request<File, ErrorResponse>({
        path: `/api/default-avatars/${id}`,
        method: 'GET',
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * @description This is a method returning single prefilled form by form name and user id with its related questions, answers and user-selected answers
     *
     * @tags Verification
     * @name GetFormByUserIdAndFormName
     * @request GET:/api/users/{id}/forms/{formName}
     * @secure
     */
    getFormByUserIdAndFormName: (
      id: number,
      formName: string,
      query?: {
        lang?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SinglePreFilledFormResponse, ErrorResponse>({
        path: `/api/users/${id}/forms/${formName}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method for submitting single filled form for specified user
     *
     * @tags Verification
     * @name SubmitForm
     * @request POST:/api/users/{id}/forms/submit
     * @secure
     */
    submitForm: (id: number, data: Result, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/users/${id}/forms/submit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This is a method for uploading single requested document for specified user
     *
     * @tags Verification
     * @name UploadDocument
     * @request POST:/api/users/{id}/documents/upload
     * @secure
     */
    uploadDocument: (
      id: number,
      data: {
        /** @format binary */
        file: File;
        documentName: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorResponse>({
        path: `/api/users/${id}/documents/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description This is a method returning a shufti url for verification startup
     *
     * @tags Verification
     * @name GetUserShuftiVerificationUrl
     * @request POST:/api/users/{id}/shufti-verification-url
     * @secure
     */
    getUserShuftiVerificationUrl: (id: number, data: ShuftiVerificationURLData, params: RequestParams = {}) =>
      this.request<ShuftiVerificationURLResponse, ErrorResponse>({
        path: `/api/users/${id}/shufti-verification-url`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning the id document of a user
     *
     * @tags Verification
     * @name GetUserIdentityDocument
     * @request GET:/api/users/{id}/identity-document
     * @secure
     */
    getUserIdentityDocument: (id: number, params: RequestParams = {}) =>
      this.request<IdentityDocumentResponse, ErrorResponse>({
        path: `/api/users/${id}/identity-document`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method uploading the user's identity document
     *
     * @tags Verification
     * @name PutUserIdentityDocument
     * @request PUT:/api/users/{id}/identity-document
     * @secure
     */
    putUserIdentityDocument: (id: number, data: ShuftiReferenceNumber, params: RequestParams = {}) =>
      this.request<IdentityDocumentResponse, ErrorResponse>({
        path: `/api/users/${id}/identity-document`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description This is a method returning the id document expiration status of a user
     *
     * @tags Verification
     * @name GetUserIdentityDocumentExpirationStatus
     * @request GET:/api/users/{id}/expiration-status
     * @secure
     */
    getUserIdentityDocumentExpirationStatus: (id: number, params: RequestParams = {}) =>
      this.request<ExpirationStatusResponse, ErrorResponse>({
        path: `/api/users/${id}/expiration-status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This a method returning all documents that the backoffice has requested for the user.
     *
     * @tags Documents
     * @name GetRequestedDocuments
     * @request GET:/api/users/{id}/requested-documents
     * @secure
     */
    getRequestedDocuments: (id: number, params: RequestParams = {}) =>
      this.request<RequestedDocumentsResponse, ErrorResponse>({
        path: `/api/users/${id}/requested-documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description History for the users uploaded documents
     *
     * @tags Documents
     * @name GetUserDocumentsHistory
     * @request GET:/api/users/{id}/documents
     * @secure
     */
    getUserDocumentsHistory: (id: number, params: RequestParams = {}) =>
      this.request<DocumentsHistoryResponse, ErrorResponse>({
        path: `/api/users/${id}/documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Pdf file of a given document by ID
     *
     * @tags Documents
     * @name GetUserDocumentPdf
     * @request GET:/api/users/{userId}/documents/{documentId}
     * @secure
     */
    getUserDocumentPdf: (userId: number, documentId: number, params: RequestParams = {}) =>
      this.request<File, ErrorResponse>({
        path: `/api/users/${userId}/documents/${documentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Bank accounts associated with that user.
     *
     * @tags Payment
     * @name GetUserBankAccounts
     * @request GET:/api/users/{id}/accounts
     * @secure
     */
    getUserBankAccounts: (id: number, params: RequestParams = {}) =>
      this.request<BankAccountsResponse, ErrorResponse>({
        path: `/api/users/${id}/accounts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a particular bank account.
     *
     * @tags Payment
     * @name DeleteUserBankAccount
     * @request DELETE:/api/users/{userId}/accounts/{accountId}
     * @secure
     */
    deleteUserBankAccount: (userId: number, accountId: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/users/${userId}/accounts/${accountId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns bank details information needed for a bank transfer (Bank, SWIFT, IBAN and etc.)
     *
     * @tags Payment
     * @name GetBankDetails
     * @request GET:/api/users/{id}/bank-details
     * @secure
     */
    getBankDetails: (id: number, params: RequestParams = {}) =>
      this.request<BankDetailsResponse, ErrorResponse>({
        path: `/api/users/${id}/bank-details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sends a message to the email, logged by the user, to invite the person to join Iuvo
     *
     * @tags Referral
     * @name SubmitReferAFriend
     * @request POST:/api/users/{id}/referral
     * @secure
     */
    submitReferAFriend: (id: number, data: ReferralRequest, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/users/${id}/referral`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Gets the referral link for the given user
     *
     * @tags Referral
     * @name GetUserReferralLink
     * @request GET:/api/users/{id}/referral-link
     * @secure
     */
    getUserReferralLink: (id: number, params: RequestParams = {}) =>
      this.request<ReferralLinkResponse, ErrorResponse>({
        path: `/api/users/${id}/referral-link`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Current user preferences regarding receiving promotional emails
     *
     * @tags NotificationSettings
     * @name GetUserNotificationSettings
     * @request GET:/api/users/{id}/notification-settings
     * @secure
     */
    getUserNotificationSettings: (id: number, params: RequestParams = {}) =>
      this.request<NotificationSettingsResponse, ErrorResponse>({
        path: `/api/users/${id}/notification-settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Submit users notification settings
     *
     * @tags NotificationSettings
     * @name PutUserNotificationSettings
     * @request PUT:/api/users/{id}/notification-settings
     * @secure
     */
    putUserNotificationSettings: (id: number, data: NotificationSettings, params: RequestParams = {}) =>
      this.request<NotificationSettingsResponse, ErrorResponse>({
        path: `/api/users/${id}/notification-settings`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
