import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { internalNavigationTabs } from 'utils/data/internalNavigationTabs';
import { getQueryParams, getTabIndex } from 'utils/helpers/queryParams';
import { internalContent } from 'styles/internalNavigationStyles';

export const InternalNavigationContent = () => {
  const [searchParams] = useSearchParams();
  const params = getQueryParams('tab', searchParams);
  const tabIndex = getTabIndex(params);

  return (
    <Box sx={internalContent}>
      {internalNavigationTabs.map(({ title, Component }, index) => (
        <Box key={`content-${title}`}>
          {tabIndex === index && <Component />}
        </Box>
      ))}
    </Box>
  );
};
