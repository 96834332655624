import React from 'react';

import { Box } from '@mui/material';

import { PageTitle } from 'components/common/PageTitle';
import { SocialMediaCard } from 'components/features/referral/SocialMediaCard';
import { SendEmailCard } from 'components/features/referral/SendEmailCard';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { getUserReferralLink } from 'api/controllers/ReferralController';
import { referralCardWrapper } from 'styles/referFriendStyles';

export const ReferAFriendScreen = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  
  const userReferralLink = getUserReferralLink(userData.userId as number);

  return (
    <>
      <PageTitle title={translate('navigation.referAFriend')} />

      {userReferralLink && (
        <Box sx={referralCardWrapper}>
          <SendEmailCard />
          <SocialMediaCard {...userReferralLink} />
        </Box>
      )}
    </>
  );
};
