export const REQUIRED_FIELD = 'This field is required';
export const REQUIRED = 'Required';
export const INVALID_PASSWORD = 'invalid password';
export const IMAGE_ACCEPT_FILES = {
  [`image/png`]: ['.png'],
  [`image/jpeg`]: ['.jpeg'],
  [`image/jpg`]: ['.jpg'],
  [`image/svg+xml`]: ['.svg'],
};
export const PDF_ACCEPT_FILE = {
  [`application/pdf`]: ['.pdf'],
};
export const VALIDATION_NICKNAME = /^\w*$/;
export const VALIDATION_PHONE = /^\+?\d+$/;
export const VALIDATION_PASSWORD =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const VALIDATION_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const VALID_STRING = /^[a-z\s]{0,255}$/i;
export const DATE_FORMAT = /\d+/g;
