import React from 'react';

import { PageTitle } from 'components/common/PageTitle';
import { useTranslations } from 'utils/context/TranslationContext';

export const FundManagementScreen = () => {
  const { translate } = useTranslations();

  return <PageTitle title={translate('navigation.fundManagement')} />;
};
