export const stepsWrapper = {
  display: 'grid',
  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
  width: '100%',
  gap: '24px',
};

export const stepsWrapperWithEmail = {
  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', xl: '1fr 1fr 1fr 1fr' },
};

export const stepContainer = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  marginBottom: { xs: '24px', lg: '0' },
};

export const verificationStepsImage = {
  width: '325px',
  maxWidth: '100%',
  margin: 'auto',
};

export const cardContent = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  height: '100%',
  minHeight: '150px',
  padding: '32px 24px',
  border: '1px solid rgba(0, 0, 0, 0.15)',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
};

export const stepHeadingStyle = {
  height: { sm: '50%' },
  fontSize: { xs: '16px', md: '18px' },
};

export const stepDescriptionStyle = {
  height: { sm: '50%' },
  fontFamily: 'primaryRegular',
  fontSize: { xs: '14px', md: '16px' },
};

// shufti verification

export const shuftiVerificationButton = {
  width: '100%',
  maxWidth: '150px',
  height: { xs: '32px', sm: '40px' },
  fontSize: '16px',
};

// small questionnaire

export const inputWrapper = {
  display: 'grid',
  gap: '32px',
  margin: '40px 0',
};

export const inputFieldWrapper = {
  display: { xs: 'block', sm: 'flex' },
};

export const autocompleteFieldLabel = {
  width: { xs: '100%', sm: '50%' },
  paddingRight: '24px',
  fontSize: { xs: '16px', sm: '18px' },
  fontFamily: 'primaryRegular',
};

export const autocompleteInputField = {
  width: { xs: '100%', sm: '50%' },
};

export const textField = {
  width: { xs: '100%', sm: '50%' },
  fontSize: { xs: '16px', sm: '18px' },
  '& .MuiInputBase-root.Mui-disabled:before': {
    borderBottomStyle: 'solid',
  },
};

export const mobileStepperWrapper = {
  display: 'grid',
  gap: '24px',
  marginTop: '24px',
};

export const mobileStepper = {
  display: 'flex',
  gap: '8px',
  padding: '0',
  '&.MuiMobileStepper-root': {
    '.MuiMobileStepper-progress': {
      borderRadius: '16px',
    },
  },
};

export const mobileStepperButtons = {
  fontSize: '16px',
  width: '100%',
  maxWidth: '100px',
  height: { xs: '32px', sm: '34px' },
  padding: '4px 16px',
};

export const nestedQuestionWrapper = {
  marginTop: { xs: '24px', sm: '0' },
};

export const nestedQuestion = {
  paddingLeft: { xs: '0', sm: '40px' },
};
