import { ID_STATUS } from 'utils/constants/identifiers';
import {
  MESSAGE_SUCCESS_CREATED,
  MESSAGE_SUCCESS_DELETED,
  MESSAGE_SUCCESS,
  MESSAGE_SUCCESS_UPDATED,
} from 'utils/constants/messages';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { showSuccessToast } from 'utils/helpers//toastHelper';

export interface Response {
  error?: Error;
}

export const successResultHandler = <TResponse>(
  response: TResponse & { status: number },
  message?: string
) => {
  const statusCode: number = response[ID_STATUS];

  if (message) {
    return showSuccessToast(message);
  }
  switch (statusCode) {
    case HttpStatusCodes.Created:
      showSuccessToast(MESSAGE_SUCCESS_CREATED);
      break;
    case HttpStatusCodes.Success:
      showSuccessToast(MESSAGE_SUCCESS_UPDATED);
      break;
    case HttpStatusCodes.NoContent:
      showSuccessToast(MESSAGE_SUCCESS_DELETED);
      break;

    default:
      showSuccessToast(MESSAGE_SUCCESS);
      break;
  }
  return null;
};
