import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Typography } from '@mui/material';

import {
  avatarOptionsContainer,
  chosenAvatar,
  button,
  buttonsContainer,
  avatarOptionsWrapper,
  avatarViewWrapper,
  avatarView,
} from 'styles/avatarStyles';
import { useTranslations } from 'utils/context/TranslationContext';
import { ROUTE_PATH_UPLOAD_AVATAR } from 'utils/constants/routes';
import {
  getAvatars,
  getUserAvatarById,
  updateAvatar,
} from 'api/controllers/AvatarController';
import { blobToFileHelper } from 'utils/helpers/blobToFileHelper';
import { MainWrapper } from 'components/common/MainWrapper';
import {
  ErrorResponse,
  HttpResponse,
  UploadAvatarResponse,
} from 'api/types/userTypes';
import { useUserData } from 'utils/context/UserDataContext';
import { AvatarWrapper } from './AvatarWrapper';

export const AvatarView = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const history = useNavigate();

  const [imageId, setImageId] = useState(userData.avatarId);
  const [avatarImage, setAvatarImage] = useState({});
  const [currentAvatarImage, setCurrentAvatarImage] = useState('');

  const avatarIds = getAvatars();
  const getCurrentAvatarImage = getUserAvatarById(imageId as number);
  const updateUserAvatar = updateAvatar(userData.userId as number);

  if (getCurrentAvatarImage) {
    blobToFileHelper(getCurrentAvatarImage, setCurrentAvatarImage);
  }

  const applyAvatarStyling = (idx: number, url: string) => {
    setAvatarImage({ [idx]: url });
  };

  const chooseAvatar = () => {
    const chosenImage: Blob = Object.values(avatarImage)[0] as Blob;

    blobToFileHelper(chosenImage, setCurrentAvatarImage);

    const file = new File(
      [chosenImage],
      `avatarImage${+Object.keys(avatarImage)[0]}.svg`,
      {
        type: 'image/svg+xml',
      }
    );

    updateUserAvatar
      .mutateAsync(file)
      .then(
        (responseData: HttpResponse<UploadAvatarResponse, ErrorResponse>) => {
          const newAvatarId: number | undefined = responseData?.data?.data?.id;
          setImageId(newAvatarId as number);
        }
      );
  };

  return (
    <MainWrapper>
      <Box sx={avatarViewWrapper}>
        <Avatar sx={avatarView} src={currentAvatarImage} />
        <Typography variant="h6">{userData.name}</Typography>
      </Box>

      <Box sx={avatarOptionsWrapper}>
        <Box sx={avatarOptionsContainer}>
          {avatarIds &&
            avatarIds.map((id: number) => (
              <AvatarWrapper
                id={id}
                key={id}
                style={
                  avatarImage[id as keyof typeof avatarImage]
                    ? chosenAvatar
                    : ''
                }
                applyAvatarStyling={applyAvatarStyling}
              />
            ))}
        </Box>

        <Box sx={buttonsContainer}>
          <Button
            sx={button}
            variant="contained"
            color="secondary"
            onClick={() => history(ROUTE_PATH_UPLOAD_AVATAR)}
          >
            {translate('buttons.upload')}
          </Button>
          <Button
            sx={button}
            variant="contained"
            disabled={!Object.values(avatarImage)[0]}
            onClick={chooseAvatar}
          >
            {translate('buttons.confirm')}
          </Button>
        </Box>
      </Box>
    </MainWrapper>
  );
};
