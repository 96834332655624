export const headerWrapper = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  background: 'rgba(46, 59, 62, 1)',
};

export const headerRightSide = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
  width: '100%',
};

// Logo
export const logoWrapper = {
  display: { xs: 'flex', md: 'none' },
  alignItems: 'center',
};

export const logoImage = {
  maxHeight: '48px',
  minHeight: '30px',
};

// Funds
export const fundsInHeader = {
  display: { xs: 'none', lg: 'flex' },
  alignItems: 'center',
  height: '40px',
  width: '100%',
  color: 'text.secondary',
  fontFamily: 'primaryBold',
};

export const fundsInHeaderWrapper = {
  padding: '0 8px',
};

export const fundsInMenu = {
  display: { xs: 'block', lg: 'none' },
  padding: '6px 16px',
};

export const fundsWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: { xs: '12px', lg: '8px' },
};

export const fundsTitle = {
  display: { xs: 'block', lg: 'none' },
  fontSize: '16px',
};

export const fundValue = {
  position: 'relative',
  padding: { xs: '0', lg: '0 8px' },
  color: { xs: 'text.primary', lg: 'text.secondary' },
  fontFamily: 'primaryRegular',
  fontSize: { xs: '14px', lg: '18px' },
  backgroundColor: { xs: 'none', lg: 'rgba(200, 200, 200, 0.2)' },
  borderRadius: '50px',
  // '&:not(:last-child)::after': {
  //   content: '"•"',
  //   position: 'absolute',
  //   right: '-16px',
  //   borderRadius: '50%',
  //   color: 'white',
  //   display: 'inline-block',
  // },
};

// Icon Buttons
export const headerButton = {
  height: '40px',
  color: 'text.secondary',
  backgroundColor: 'rgba(200, 200, 200, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(200, 200, 200, 0.4)',
  },
};

export const iconButton = {
  width: '40px',
};

// Withdraw & Deposti Buttons
export const actionButton = {
  width: '100%',
  maxWidth: '140px',
  height: '40px',
  padding: '4px 24px',
  fontSize: '18px',
};

// User Profile
export const profileInMenu = {
  display: { xs: 'flex', sm: 'none' },
  padding: '6px 16px',
};

export const profileButton = {
  minWidth: '40px',
  padding: { xs: '0', sm: '4px' },
};

export const avatar = {
  width: { xs: '40px', sm: '32px' },
  height: { xs: '40px', sm: '32px' },
};

export const avatarBackground = {
  background: '#F1F6F7',
};

export const userInfoWrapper = {
  padding: '0 4px 0 8px',
};

export const noUserInfoWrapper = {
  padding: '0',
};

export const userName = {
  maxWidth: '150px',
  fontFamily: 'primaryBold',
  fontSize: { xs: '16px', sm: '14px' },
};

export const userAccountId = {
  fontFamily: { xs: 'primaryRegular', sm: 'primaryLight' },
  fontSize: { xs: '14px', sm: '12px' },
};

export const profileMenu = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))',
  mt: 1,
};

export const profileLink = {
  padding: 0,
  color: 'text.primary',
  fontSize: '18px',
  fontFamily: 'primarySemiBold',
  '&:hover': {
    color: 'primary.main',
  },
};

export const closeButton = {
  float: 'right',
  margin: '8px',
  fontSize: '24px',
  cursor: 'pointer',
};

export const list = {
  display: 'grid',
  gridGap: '24px',
  paddingTop: '48px',
  paddingLeft: '24px',
};

export const link = {
  color: 'text.primary',
  fontSize: '16px',
  fontFamily: 'primarySemiBold',
  textTransform: 'uppercase',
  '&:hover': {
    color: 'primary.main',
  },
};

export const navigationWrapper = {
  height: '100%',
};
