import React, { useState, useEffect } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Box } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { InternalNavigationContent } from 'components/common/internalNavigation/InternalNavigationContent';
import { MainWrapper } from 'components/common/MainWrapper';
import { InternalNavigationMenu } from 'components/common/internalNavigation/InternalNavigationMenu';
import { mainWrapperProfile } from 'styles/mainWrapperStyles';
import { backButton, backButtonArrow } from 'styles/internalNavigationStyles';
import theme from 'styles/customTheme';
import { useTranslations } from 'utils/context/TranslationContext';
import {
  getQueryParams,
  getTabIndex,
  getTabTitle,
  setQueryParams,
} from 'utils/helpers/queryParams';
import { internalNavigationTabs } from 'utils/data/internalNavigationTabs';

export const InternalNavigationLayout = () => {
  const { translate } = useTranslations();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const params = getQueryParams('tab', searchParams);
  const tabIndex = getTabIndex(params || internalNavigationTabs[0].title);

  const [currentlySelected, setCurrentlySelected] = useState(
    isMobile ? -1 : tabIndex || 0
  );
  const tabTitle = getTabTitle(currentlySelected);

  useEffect(() => {
    if (params === null) {
      setQueryParams(search, tabTitle, 'tab');
    }
  }, [searchParams]);

  return (
    <MainWrapper styles={mainWrapperProfile}>
      {isMobile && currentlySelected !== -1 ? (
        <Box>
          <Button
            sx={backButton}
            variant="text"
            onClick={() => setCurrentlySelected(-1)}
          >
            <ArrowBackIos sx={backButtonArrow} />
            {translate('buttons.back')}
          </Button>
        </Box>
      ) : null}
      {currentlySelected !== -1 && <InternalNavigationContent />}
      {(isMobile && currentlySelected === -1) || !isMobile ? (
        <InternalNavigationMenu
          currentlySelected={currentlySelected}
          setCurrentlySelected={setCurrentlySelected}
          searchParams={searchParams}
        />
      ) : null}
    </MainWrapper>
  );
};
