import React from 'react';
import { QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@mui/material/styles';
import { createBrowserHistory } from 'history';

import theme from 'styles/customTheme';
import { UserDataProvider } from 'utils/context/UserDataContext';
import { queryClient } from 'utils/hooks/query/queryClient';
import { TranslationsProvider } from 'utils/context/TranslationContext';
import { CustomRouter } from 'components/common/CustomRouter';
import { ToastMessage } from 'components/common/form/ToastMessage';
import { AppRoutes } from 'AppRoutes';

export const customHistory = createBrowserHistory();

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <CustomRouter history={customHistory}>
      <ThemeProvider theme={theme}>
        <UserDataProvider>
          <TranslationsProvider>
            <ToastMessage />
            <AppRoutes />
          </TranslationsProvider>
        </UserDataProvider>
      </ThemeProvider>
    </CustomRouter>
  </QueryClientProvider>
);
