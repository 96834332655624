import { DocumentsScreen } from 'components/pages/profiles/DocumentsScreen';
import { FundManagementScreen } from 'components/pages/profiles/FundManagementScreen';
import { HelpScreen } from 'components/pages/profiles/HelpScreen';
import { NotificationsScreen } from 'components/pages/profiles/NotificationsScreen';
import { ReferAFriendScreen } from 'components/pages/profiles/ReferralScreen';
import { SecurityScreen } from 'components/pages/profiles/SecurityScreen';
import { SettingsScreen } from 'components/pages/profiles/SettingsScreen';

import documents from 'assets/images/menu/documents.svg';
import fundManagement from 'assets/images/menu/fundManagement.svg';
import profileSettings from 'assets/images/menu/profileSettings.svg';
import notifications from 'assets/images/menu/notifications.svg';
import help from 'assets/images/menu/help.svg';
import security from 'assets/images/menu/security.svg';
import referAFriend from 'assets/images/menu/referAFriend.svg';

interface InternalNavigationTabsProps {
  title: string;
  Component: () => JSX.Element;
  image: string;
}

export const internalNavigationTabs: InternalNavigationTabsProps[] = [
  {
    title: 'profileSettings',
    Component: SettingsScreen,
    image: profileSettings,
  },
  {
    title: 'security',
    Component: SecurityScreen,
    image: security,
  },
  {
    title: 'documents',
    Component: DocumentsScreen,
    image: documents,
  },
  {
    title: 'notifications',
    Component: NotificationsScreen,
    image: notifications,
  },
  {
    title: 'referAFriend',
    Component: ReferAFriendScreen,
    image: referAFriend,
  },
  {
    title: 'fundManagement',
    Component: FundManagementScreen,
    image: fundManagement,
  },
  {
    title: 'help',
    Component: HelpScreen,
    image: help,
  },
];
