import React, { SetStateAction, useState } from 'react';

import Dropzone, { Accept } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';

import {
  button,
  headings,
  uploadContainer,
  subheading,
  fileUploadWrapper,
  uploadMessage,
  biggerFileErrorMessage,
} from 'styles/inputFieldsStyles';
import upload from 'assets/images/upload.svg';
import { useTranslations } from 'utils/context/TranslationContext';
import { UploadedFiles } from 'components/common/inputFields/UploadedFiles';

interface FileUploadComponentProps {
  id: string;
  uploadFile: Function;
  uploadFileMessage: string;
  acceptFile: Accept;
}

export const FileUploadComponent = (props: FileUploadComponentProps) => {
  const { id, uploadFile, uploadFileMessage, acceptFile } = props;

  const { translate } = useTranslations();

  const [myFiles, setMyFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);

  const { setValue, getValues } = useForm();

  const file = getValues(id);

  return (
    <Box sx={fileUploadWrapper}>
      <Dropzone
        onDrop={(acceptedFiles) => {
          setValue(id, acceptedFiles, { shouldValidate: true });
          setMyFiles(acceptedFiles as SetStateAction<never[]>);
          setIsUploaded(true);
        }}
        multiple={false}
        accept={acceptFile}
        maxSize={2000000}
      >
        {({ getRootProps, getInputProps, fileRejections }) => {
          const isFileTooLarge =
            fileRejections.length > 0 && fileRejections[0].file.size > 2000000;

          return (
            <Box component="div" sx={uploadContainer} {...getRootProps()}>
              <Typography variant="h2" sx={headings}>
                {translate('labels.uploadFiles')}
              </Typography>

              <img src={upload} alt={translate('altText.upload')} />

              <Typography variant="h2" sx={headings}>
                {translate('labels.dragAndDropFiles')}
              </Typography>
              <Typography sx={subheading} variant="h5">
                {translate('labels.or')}
              </Typography>
              <Typography>
                <input {...getInputProps()} />
                <Button component="label" variant="contained" sx={button}>
                  {translate('buttons.browseFiles')}
                </Button>
              </Typography>
              <Typography variant="body2" sx={uploadMessage}>
                {uploadFileMessage}
              </Typography>
              {isFileTooLarge && (
                <Typography variant="body1" sx={biggerFileErrorMessage}>
                  File too large. Please choose smaller file
                </Typography>
              )}
            </Box>
          );
        }}
      </Dropzone>
      <UploadedFiles
        isUploaded={isUploaded}
        myFiles={myFiles}
        setMyFiles={setMyFiles}
        setIsUploaded={setIsUploaded}
        file={file}
        uploadFile={uploadFile}
      />
    </Box>
  );
};
