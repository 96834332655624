export const documentsWrapper = {
  display: 'grid',
  gap: '64px',
};

export const documentsHistoryWrapper = {
  overflow: 'auto',
};

export const documentsSubtitle = {
  marginBottom: '24px',
  fontFamily: 'primaryBold',
};

export const missingDataMessage = {
  color: 'rgba(0, 0, 0, 0.40)',
};

// change document

export const changeDocumentInputWrapper = {
  position: 'relative',
};

export const changeDocumentWrapper = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  right: '0',
  bottom: '0',
  marginBottom: '16px',
};

export const changeDocumentText = {
  maxWidth: '80%',
  textAlign: 'center',
  fontFamily: 'primarySemibold',
  fontSize: '12px',
};

// history table

export const tableHeadCell = {
  fontSize: '18px',
  fontFamily: 'primarySemibold',
};

export const tableBodyCell = {
  fontSize: '16px',
  fontFamily: 'primaryRegular',
};

export const imageTextTableCell = {
  display: 'flex',
  gap: '24px',
  alignItems: 'center',
};

export const imageTableCell = {
  textAlign: 'center',
};

export const image = {
  cursor: 'pointer',
};

// requested documents

export const requestedDocumentsWrapper = {
  display: 'flex',
  minHeight: '64px',
};

export const requestedDocumentsTitles = {
  width: '80%',
  display: 'grid',
  gap: '8px',
  marginTop: '16px',
  marginBottom: '8px',
};

export const requestedDocumentsTitle = {
  fontFamily: 'primaryRegular',
  fontSize: '18px',
};

export const requestedDocumentsDetails = {
  color: 'rgba(0, 0, 0, 0.5)',
};

export const requestedDocumentsImageWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
  placeSelf: 'center',
  width: '20%',
  cursor: 'pointer',
};

export const horizontalLine = {
  border: '0.5px solid rgba(0, 0, 0, 0.2)',
};
