import React from 'react';

import { PageTitle } from 'components/common/PageTitle';
import { useTranslations } from 'utils/context/TranslationContext';
import { SecurityForm } from 'components/features/profile/forms/SecurityForm';
import { useUserData } from 'utils/context/UserDataContext';
import { CircularProgress } from '@mui/material';

export const SecurityScreen = () => {
  const { userData } = useUserData();
  const { translate } = useTranslations();

  return (
    <>
      <PageTitle title={translate('navigation.security')} />
      {userData ? (
        <SecurityForm userData={userData} />
      ) : (
        <CircularProgress color="primary" />
      )}
    </>
  );
};
