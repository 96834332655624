export const blobToFileHelper = (data: Blob, state?: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(data);
  reader.onloadend = () => {
    let base64data = reader.result as string;

    // replace application/octet-stream with image/svg+xml
    base64data = base64data.replace(
      'application/octet-stream',
      'image/svg+xml'
    );

    state(base64data);
  };
};
