import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { uploadDocument } from 'api/controllers/DocumentsController';
import { MainWrapper } from 'components/common/MainWrapper';
import { FileUploadComponent } from 'components/common/inputFields/FileUploadComponent';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { ROUTE_PATH_PROFILE } from 'utils/constants/routes';
import { PDF_ACCEPT_FILE } from 'utils/constants/inputs';
import { backButton, backButtonArrow } from 'styles/internalNavigationStyles';
import { uploadWrapper } from 'styles/inputFieldsStyles';

export const UploadDocumentScreen = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const navigate = useNavigate();

  const uploadUserDocument = uploadDocument(userData.userId as number);

  const handleBackToDocumentsTab = () => {
    localStorage.removeItem('documentName');
    navigate(`${ROUTE_PATH_PROFILE}?tab=documents`);
  };

  const uploadFile = (file: File[]) => {
    const documentName = localStorage.getItem('documentName') as string;

    const requestBody = {
      file: file[0],
      documentName,
    };

    uploadUserDocument
      .mutateAsync(requestBody)
      .then(() => handleBackToDocumentsTab());
  };

  return (
    <MainWrapper>
      <Button sx={backButton} variant="text" onClick={handleBackToDocumentsTab}>
        <ArrowBackIos sx={backButtonArrow} />
        {translate('buttons.back')}
      </Button>

      <Box sx={uploadWrapper}>
        <FileUploadComponent
          id="uploadDocument"
          uploadFile={uploadFile}
          uploadFileMessage={translate('labels.uploadMessageDocument')}
          acceptFile={PDF_ACCEPT_FILE}
        />
      </Box>
    </MainWrapper>
  );
};
