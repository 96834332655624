import { ACCESS_TOKEN, BEARER_TOKEN } from 'utils/constants/common';
import { viravaService } from 'utils/config/virava';

export const apiConfigBase = {
  securityWorker: async () => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (!token) {
      viravaService.logout();
      return {};
    }

    return {
      headers: {
        // TODO: Language code should be set from selector
        'Accept-Language': 'EN',
        Authorization: `${BEARER_TOKEN} ${token}`,
      },
    };
  },
};
