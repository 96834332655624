export const USER_DATA_CONFIG = {
  userId: undefined,
  name: undefined,
  investmentStatus: undefined,
  progress: undefined,
  avatarId: undefined,
  userStatus: undefined,
  funds: [],
  requiredActions: [],
  permissions: {},
};
