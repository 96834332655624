import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Logout, Person } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ROUTE_PATH_PROFILE } from 'utils/constants/routes';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { viravaService } from 'utils/config/virava';

import { HeaderFunds } from 'components/common/header/HeaderFunds';
import { ProfileInfo } from 'components/common/header/HeaderProfileInfo';

import theme from 'styles/customTheme';
import {
  fundsInMenu,
  fundsTitle,
  headerButton,
  profileButton,
  profileInMenu,
  profileMenu,
} from 'styles/headerStyles';
import { anchorOrigin, transformOrigin } from 'styles/commonStyles';

export const HeaderProfile = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    viravaService.logout();
  };

  return (
    <>
      <Tooltip title={translate('labels.profileMenu')}>
        <Button
          sx={{ ...headerButton, ...profileButton }}
          aria-label={translate('labels.userProfile')}
          size="large"
          onClick={openProfileMenu}
          aria-controls={translate('labels.userProfile')}
          aria-haspopup="true"
          aria-expanded={open}
        >
          <ProfileInfo show={useMediaQuery(theme.breakpoints.up('sm'))} />
        </Button>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeProfileMenu}
        onClick={closeProfileMenu}
        PaperProps={{
          elevation: 0,
          sx: profileMenu,
        }}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
      >
        <Box sx={profileInMenu}>
          <ProfileInfo />
        </Box>
        <Box sx={fundsInMenu}>
          <Typography sx={fundsTitle}>
            {translate('labels.accountBalance')}:
          </Typography>
          <HeaderFunds funds={userData.funds} />
        </Box>
        <NavLink to={ROUTE_PATH_PROFILE}>
          <MenuItem>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            {translate('navigation.userProfile')}
          </MenuItem>
        </NavLink>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {translate('navigation.signout')}
        </MenuItem>
      </Menu>
    </>
  );
};
