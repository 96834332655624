import React from 'react';

import { CircularProgress } from '@mui/material';

import { PageTitle } from 'components/common/PageTitle';
import { ProfileSettingsForm } from 'components/features/profile/forms/ProfileSettingsForm';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';

export const SettingsScreen = () => {
  const { userData } = useUserData();
  const { translate } = useTranslations();

  return (
    <>
      <PageTitle title={translate('navigation.profileSettings')} />
      {userData?.userId ? (
        <ProfileSettingsForm userId={userData?.userId} />
      ) : (
        <CircularProgress color="primary" />
      )}
    </>
  );
};
