interface EnvironmentConfig {
  userServiceApiUrl: string;
  keycloakClientId: string;
  keycloakBaseUrl: string;
  keycloakRealm: string;
  environment: string;
}

export const appConfig: EnvironmentConfig = {
  userServiceApiUrl: process.env.REACT_APP_USER_SERVICE_API_URL || '',
  keycloakBaseUrl: process.env.REACT_APP_KEYCLOAK_BASE_URL || '',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM || '',
  environment: process.env.REACT_APP_ENVIRONMENT || '',
};
