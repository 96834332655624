import React, { useState, FocusEvent, useEffect } from 'react';

import { useForm } from "react-hook-form";
import { Box, CircularProgress, useMediaQuery } from '@mui/material';

import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { SMALL_QUESTIONNAIRE } from 'utils/constants/verification';
import theme from 'styles/customTheme';
import { PreFilledQuestionResponse, UserAnswer } from 'api/types/userTypes';
import {
  getFormByUserIdAndFormName,
  submitForm,
} from 'api/controllers/VerificationController';
import { MainWrapper } from 'components/common/MainWrapper';
import { PageTitle } from 'components/common/PageTitle';
import { SmallQuestionnaireForm } from 'components/features/verifications/forms/SmallQuestionnaireForm';
import { ResponsiveSmallQuestionnaireForm } from 'components/features/verifications/forms/ResponsiveSmallQuestionnaireForm';
import { getUrl } from 'utils/helpers/verification';
import { ROUTE_PATH_PROFILE } from 'utils/constants/routes';
import { loader } from 'styles/commonStyles';

export const SmallQuestionnaireVerificationScreen = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();

  const { register, setValue, formState: { errors }, clearErrors, handleSubmit } = useForm()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);

  const addAnswer = (answer: UserAnswer) => {
    const newUserAnswers = [...userAnswers];

    const index = newUserAnswers.findIndex(
      (question: UserAnswer) => question.questionId === answer.questionId
    );

    if (index > -1) {
      newUserAnswers.splice(index, 1, answer);
      setUserAnswers([...newUserAnswers]);
    } else {
      setUserAnswers([...newUserAnswers, answer]);
    }
  };

  const deleteAnswer = (answer: UserAnswer) => {
    const newUserAnswers = [...userAnswers];

    newUserAnswers.splice(
      newUserAnswers.findIndex(
        (question: UserAnswer) => question.questionId === answer.questionId
      ),
      1
    );

    setUserAnswers([...newUserAnswers]);
  };

  const onTextFieldBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    question: PreFilledQuestionResponse
  ) => {
    const answer = {
      questionId: question.id as number,
      content: e.target.value,
      dataType: question.dataType as string,
    };

    if (e.target.value) {
      addAnswer(answer);
    } else {
      deleteAnswer(answer);
    }
  };

  const smallQuestionnaireData = getFormByUserIdAndFormName(
    userData.userId as number,
    SMALL_QUESTIONNAIRE
  );
  const submitFormData = submitForm(userData.userId as number);

  const onSubmit = () => {
    const requestBody = {
      formName: SMALL_QUESTIONNAIRE,
      userAnswers,
    };

    submitFormData.mutateAsync(requestBody).then(() => {
      if (userData.requiredActions && userData.requiredActions.length > 1) {
        window.location.href = `${window.location.origin}${getUrl(
          (userData.requiredActions as Array<{ type: string }>)[1]?.type
        )}`;
      } else {
        window.location.href = `${ROUTE_PATH_PROFILE}?tab=profileSettings`;
      }
    });
  };

  // delete all user answers when the screen is resized
  useEffect(() => {
    setUserAnswers([]);
  }, [isMobile]);

  return (
    <MainWrapper>
      <PageTitle title={translate('navigation.smallQuestionnaire')} />
      {smallQuestionnaireData ? (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {isMobile ? (
            <ResponsiveSmallQuestionnaireForm
              smallQuestionnaireData={smallQuestionnaireData}
              userAnswers={userAnswers}
              addAnswer={addAnswer}
              deleteAnswer={deleteAnswer}
              onTextFieldBlur={onTextFieldBlur}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
            />
          ) : (
            <SmallQuestionnaireForm
              smallQuestionnaireData={smallQuestionnaireData}
              addAnswer={addAnswer}
              deleteAnswer={deleteAnswer}
              onTextFieldBlur={onTextFieldBlur}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
            />
          )}
        </Box>
      ) : (
        <Box sx={loader}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </MainWrapper>
  );
};
