import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { LANGUAGE_CONFIG } from 'utils/constants/context/i18n';
import { handleRequiredFields } from 'utils/helpers/formFieldsHelper';
import { VALIDATION_EMAIL, VALID_STRING } from 'utils/constants/inputs';
import { submitReferAFriend } from 'api/controllers/ReferralController';
import emailIcon from 'assets/images/social-media/email-icon.svg';
import { button } from 'styles/verificationMessage';
import {
  bodyCardWrapper,
  referralButton,
  headerSocialMediaCardSection,
  referralCard,
  referralCardTitle,
  shareIconContainer,
  invitationLanguage,
  emailLanguageWrapper,
  textAreaEmailMessage,
  emailField,
  langMenuLabel,
  emailCardBackground,
} from 'styles/referFriendStyles';

type FormData = {
  email: string;
  emailMessage: string;
  invitationLanguage: string;
};

export const SendEmailCard = () => {
  const { translate, currentLanguage } = useTranslations();
  const { userData } = useUserData();
  const [emailLanguage, setEmailLanguage] = useState<string>(currentLanguage);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const handleChange = (event: SelectChangeEvent) => {
    setEmailLanguage(event.target.value);
  };

  const submitReferAFriendData = submitReferAFriend(userData.userId as number);

  const onSubmit = (data: FormData) => {
    const requestBody = {
      email: data.email,
      message: data.emailMessage,
      language: !data.invitationLanguage
        ? currentLanguage
        : data.invitationLanguage,
    };
    submitReferAFriendData.mutateAsync(requestBody).then(() => reset());
  };

  useEffect(() => {
    setEmailLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <Card elevation={4} sx={{ ...referralCard, ...emailCardBackground }}>
      <Box sx={{ ...headerSocialMediaCardSection }} mb="8px">
        <Box sx={shareIconContainer}>
          <Box
            component="img"
            alt={translate('altText.email')}
            src={emailIcon}
            width="34px"
          />
        </Box>
        <Box ml="8px">
          <Typography variant="body1" sx={referralCardTitle}>
            {translate('labels.inviteViaEmail')}
          </Typography>
          <Typography variant="body1">
            {translate('labels.typeEmailAddress')}
          </Typography>
        </Box>
      </Box>
      <Box
        component="form"
        sx={bodyCardWrapper}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          {...register('email', {
            pattern: {
              value: VALIDATION_EMAIL,
              message: translate('messages.invalidEmail'),
            },
            required: true,
          })}
          sx={emailField}
          placeholder={`${translate('labels.email')} *`}
          variant="standard"
          helperText={handleRequiredFields(
            errors,
            'email',
            translate('messages.requiredFieldMessage')
          )}
          error={Boolean(errors.email)}
        />
        <TextareaAutosize
          {...register('emailMessage', { pattern: VALID_STRING })}
          placeholder={translate('labels.writeAMessage')}
          minRows={3}
          style={textAreaEmailMessage}
        />

        <Box sx={emailLanguageWrapper}>
          <Typography sx={langMenuLabel}>
            {translate('labels.invitationLanguage')}
          </Typography>
          <Select
            value={emailLanguage}
            onChange={handleChange}
            sx={invitationLanguage}
          >
            {LANGUAGE_CONFIG.languages.map(({ code }) => (
              <MenuItem
                {...(register('invitationLanguage'), { pattern: VALID_STRING })}
                key={code}
                value={code}
              >
                {code}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          sx={{ ...button, ...referralButton }}
          variant="contained"
          aria-label={translate('buttons.sendInvite')}
          type="submit"
        >
          {translate('buttons.sendInvite')}
        </Button>
      </Box>
    </Card>
  );
};
