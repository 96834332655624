export const wrapper = {
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  background: 'rgba(225, 231, 231, 1)',
};

export const warningMessageContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

export const warningIcon = {
  width: { xs: '16px', sm: '24px' },
  height: { xs: '16px', sm: '24px' },
  color: 'warning.main',
};

export const warningMessage = {
  color: 'warning.main',
  fontSize: { xs: '16px', sm: '18px' },
};

export const button = {
  width: '100%',
  maxWidth: '140px',
  height: { xs: '32px', sm: '34px' },
  padding: '4px 24px',
  fontSize: '16px',
  border: '1px solid',
  borderColor: 'primary.main',
};
