import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { loginUser } from 'api/controllers/UserController';
import { UserLogin } from 'api/types/userTypes';

import { MainNavigation } from 'components/common/mainNavigation/MainNavigation';
import Header from 'components/common/header';
import VerificationMessage from 'components/common/verificationMessage';
import { useUserData } from 'utils/context/UserDataContext';

export const MainLayout = () => {
  const { setData, userData } = useUserData();
  const login = loginUser();

  useEffect(() => {
    if (login) {
      setData(login.user as UserLogin);
    }
  }, [login?.user?.userId]);

  return (
    <>
      <Header />
      <MainNavigation />
      {userData.requiredActions && userData.requiredActions.length > 0 ? (
        <VerificationMessage />
      ) : null}
      <Box component="main">
        <Outlet />
      </Box>
    </>
  );
};
