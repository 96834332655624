import React from 'react';

import { PageTitle } from 'components/common/PageTitle';
import { MainWrapper } from 'components/common/MainWrapper';

export const DepositScreen = () => (
  <MainWrapper>
    <PageTitle title="Deposit Screen" />
  </MainWrapper>
);
