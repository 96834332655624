import React from 'react';

import ReactDOM from 'react-dom/client';

import reportWebVitals from 'reportWebVitals';

import { App } from 'App';
import {
  authConfig,
  configCustom,
  viravaCustom,
  viravaService,
} from 'utils/config/virava';
import 'styles/css/index.css';

viravaService.init(authConfig);
viravaCustom.init(configCustom);

viravaService.login().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
