import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { MainLayout } from 'components/layouts/MainLayout';
import { BankTransferScreen } from 'components/pages/transactions/BankTransferScreen';
import { CardDepositScreen } from 'components/pages/transactions/CardDepositScreen';
import { DepositScreen } from 'components/pages/transactions/DepositScreen';
import { FailedScreen } from 'components/pages/transactions/FailedScreen';
import { SuccessfulScreen } from 'components/pages/transactions/SuccessfulScreen';
import { WentWrongScreen } from 'components/pages/transactions/WentWrongScreen';
import { WithdrawScreen } from 'components/pages/transactions/WithdrawScreen';
import { SmallQuestionnaireVerificationScreen } from 'components/pages/verifications/SmallQuestionnaireVerificationScreen';
import { MainVerificationScreen } from 'components/pages/verifications/MainVerificationScreen';

import {
  ALL_ROUTES_WILDCARD,
  ROUTE_PATH_HOME,
  ROUTE_PATH_VERIFICATION,
  ROUTE_PATH_SMALL_QUESTIONNAIRE,
  ROUTE_PATH_TRANSACTION_DEPOSIT,
  ROUTE_PATH_TRANSACTION_WITHDRAW,
  ROUTE_PATH_TRANSACTION_FAILED,
  ROUTE_PATH_TRANSACTION_WENT_WRONG,
  ROUTE_PATH_TRANSACTION_SUCCESSFUL,
  ROUTE_PATH_TRANSACTION_CARD_DEPOSIT,
  ROUTE_PATH_TRANSACTION_BANK_TRANSFER,
  ROUTE_PATH_PROFILE,
  ROUTE_PATH_PROFILE_AVATAR,
  ROUTE_PATH_UPLOAD_AVATAR,
  ROUTE_PATH_SHUFTI,
  ROUTE_PATH_UPLOAD_DOCUMENT,
} from 'utils/constants/routes';
import { InternalNavigationLayout } from 'components/layouts/InternalNavigationLayout';
import { AvatarScreen } from 'components/pages/profiles/AvatarScreen';
import { UploadAvatarScreen } from 'components/pages/profiles/UploadAvatarScreen';
import { ShuftiVerification } from 'components/features/verifications/ShuftiVerification';
import { UploadDocumentScreen } from 'components/pages/documents/UploadDocumentScreen';

export const AppRoutes = () => (
  <Routes>
    <Route
      path={ALL_ROUTES_WILDCARD}
      element={<Navigate to={ROUTE_PATH_HOME} />}
    />
    <Route path={ROUTE_PATH_HOME} element={<MainLayout />}>
      <Route path={ROUTE_PATH_PROFILE} element={<InternalNavigationLayout />} />
      <Route
        path={ROUTE_PATH_TRANSACTION_WITHDRAW}
        element={<WithdrawScreen />}
      />
      <Route
        path={ROUTE_PATH_TRANSACTION_DEPOSIT}
        element={<DepositScreen />}
      />
      <Route
        path={ROUTE_PATH_VERIFICATION}
        element={<MainVerificationScreen />}
      />
      <Route path={ROUTE_PATH_SHUFTI} element={<ShuftiVerification />} />
      <Route
        path={ROUTE_PATH_SMALL_QUESTIONNAIRE}
        element={<SmallQuestionnaireVerificationScreen />}
      />
      <Route
        path={ROUTE_PATH_TRANSACTION_BANK_TRANSFER}
        element={<BankTransferScreen />}
      />
      <Route
        path={ROUTE_PATH_TRANSACTION_CARD_DEPOSIT}
        element={<CardDepositScreen />}
      />
      <Route path={ROUTE_PATH_TRANSACTION_FAILED} element={<FailedScreen />} />
      <Route
        path={ROUTE_PATH_TRANSACTION_SUCCESSFUL}
        element={<SuccessfulScreen />}
      />
      <Route
        path={ROUTE_PATH_TRANSACTION_WENT_WRONG}
        element={<WentWrongScreen />}
      />
      <Route path={ROUTE_PATH_PROFILE_AVATAR} element={<AvatarScreen />} />
      <Route path={ROUTE_PATH_UPLOAD_AVATAR} element={<UploadAvatarScreen />} />
      <Route
        path={ROUTE_PATH_UPLOAD_DOCUMENT}
        element={<UploadDocumentScreen />}
      />
    </Route>
  </Routes>
);
