import React, { useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material';

import {
  getUserShuftiVerificationUrl,
  putUserIdentityDocument,
} from 'api/controllers/IdentityDocumentController';
import { ShuftiReferenceNumber } from 'api/types/userTypes';
import { loader } from 'styles/commonStyles';
import { getUrl } from 'utils/helpers/verification';
import {
  ROUTE_PATH_HOME,
  ROUTE_PATH_PROFILE,
  ROUTE_PATH_VERIFICATION,
} from 'utils/constants/routes';
import { useUserData } from 'utils/context/UserDataContext';
import { IUVO_PLATFORM_PATH } from 'utils/constants/mainNavigation';
import { appConfig } from 'utils/config/app.config';
import { ACCESS_TOKEN, BEARER_TOKEN } from 'utils/constants/common';
import { errorResultHandler } from 'utils/helpers/errorResultHandler';
import { SHUFTI_ACTION } from 'utils/constants/verification';

export const ShuftiVerification = () => {
  const { userData } = useUserData();

  if (!userData.userId || localStorage.getItem('shuftiReference') === 'mock') {
    return null;
  }

  const getVerificationUrl = getUserShuftiVerificationUrl(
    userData.userId as number
  );

  useEffect(() => {
    if (!localStorage.getItem('shuftiReference')) {
      localStorage.setItem('shuftiReference', 'mock');

      const shuftiVerificationUrl = `${appConfig.userServiceApiUrl}/api/users/${userData.userId}/shufti-verification-url`;
      const requestBody = {
        redirectUrl: `${window.location.origin}/verification/shufti`,
      };

      fetch(shuftiVerificationUrl, {
        method: 'POST',
        headers: {
          Authorization: `${BEARER_TOKEN} ${localStorage.getItem(
            ACCESS_TOKEN
          )}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      }).then((response) =>
        response.json().then((responseData) => {
          if (responseData.data) {
            const { data } = responseData;
            const reference = data?.reference;
            const verificationUrl = data?.verificationUrl;

            if (reference && verificationUrl) {
              localStorage.setItem('shuftiReference', reference);
              window.location.href = verificationUrl;
            }
          } else {
            errorResultHandler(responseData.error);
            localStorage.removeItem('shuftiReference');
          }
        })
      );
    }
  }, []);

  const bool = userData.userId && localStorage.getItem('shuftiReference');
  const responseData: ShuftiReferenceNumber = {
    referenceNumber: localStorage.getItem('shuftiReference') as string,
  };
  const putIdDocument = putUserIdentityDocument(userData.userId as number);

  if (bool && localStorage.getItem('shuftiReference') !== 'mock') {
    putIdDocument
      .mutateAsync(responseData)
      .then(() => {
        if (bool) {
          localStorage.removeItem('shuftiReference');

          // navigate to documents when the action is missing (when clicking change document in documents tab)
          if (
            (userData.requiredActions as Array<{ type: string }>)[0]?.type ===
            SHUFTI_ACTION
          ) {
            if (
              userData.requiredActions &&
              userData.requiredActions?.length > 1
            ) {
              window.location.href = `${window.location.origin}${getUrl(
                (userData.requiredActions as Array<{ type: string }>)[1]?.type
              )}`;
            } else {
              window.location.href = `${IUVO_PLATFORM_PATH}`;
            }
          } else {
            window.location.href = `${window.location.origin}${ROUTE_PATH_PROFILE}?tab=documents`;
          }
        }
      })
      .catch(() => {
        if (bool) {
          // navigate to documents when the action is missing (when clicking change document in documents tab)
          if (
            (userData.requiredActions as Array<{ type: string }>)[0]?.type ===
            SHUFTI_ACTION
          ) {
            localStorage.removeItem('shuftiReference');
            window.location.href = `${window.location.origin}${
              userData.userStatus === 'NORMAL'
                ? ROUTE_PATH_HOME
                : ROUTE_PATH_VERIFICATION
            }`;
          } else {
            window.location.href = `${window.location.origin}${ROUTE_PATH_PROFILE}?tab=documents`;
          }
        }
      });
  }
  return getVerificationUrl ? (
    <Box sx={loader}>
      <CircularProgress color="primary" />
    </Box>
  ) : null;
};
