import { customHistory } from 'App';
import { internalNavigationTabs } from 'utils/data/internalNavigationTabs';

export const setQueryParams = (search: string, value: string, key: string) => {
  const params = new URLSearchParams(search);
  params.set(key, value);
  customHistory.push({ search: params.toString() });
};

export const getQueryParams = (key: string, searchParams: URLSearchParams) => {
  const param = searchParams.get(key);
  return param;
};

export const getTabIndex = (params: string | null) => {
  let tabIndex;

  internalNavigationTabs.map((tab, index) => {
    if (tab.title === params) {
      tabIndex = index;
    }
    return tab;
  });

  return tabIndex;
};

export const getTabTitle = (tabIndex: number | undefined) => {
  let tabTitle = '';

  internalNavigationTabs.map((tab, index) => {
    if (index === tabIndex) {
      tabTitle = tab.title;
    }
    return tab;
  });

  return tabTitle;
};
