import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { internalNavigationTabs } from 'utils/data/internalNavigationTabs';
import { useTranslations } from 'utils/context/TranslationContext';

import {
  avatar,
  internalNavigationTabsMenu,
  internalNavigationTilesMenu,
  tab,
  tabsContainer,
  profileInfoContainer,
  userName,
  status,
  statusTitle,
  tile,
  tileImageContainer,
  tilesContainer,
  tileImage,
  iconButton,
  editIcon,
} from 'styles/internalNavigationStyles';
import theme from 'styles/customTheme';
import goldenCup from 'assets/images/goldcup.svg';
import { ROUTE_PATH_PROFILE_AVATAR } from 'utils/constants/routes';
import {
  getQueryParams,
  getTabIndex,
  setQueryParams,
} from 'utils/helpers/queryParams';
import { useUserData } from 'utils/context/UserDataContext';
import { getUserAvatarById } from 'api/controllers/AvatarController';
import { blobToFileHelper } from 'utils/helpers/blobToFileHelper';

interface InternalNavigationProps {
  setCurrentlySelected: React.Dispatch<React.SetStateAction<number>>;
  currentlySelected?: number;
  searchParams: URLSearchParams;
}

interface InternalNavigationTilesProps {
  setCurrentlySelected: React.Dispatch<React.SetStateAction<number>>;
}

const ProfileInfo = () => {
  const history = useNavigate();
  const { userData } = useUserData();

  const [currentAvatarImage, setCurrentAvatarImage] = useState('');

  const getCurrentAvatarImage = getUserAvatarById(userData.avatarId as number);

  if (getCurrentAvatarImage) {
    blobToFileHelper(getCurrentAvatarImage, setCurrentAvatarImage);
  }

  return (
    <Box sx={profileInfoContainer}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <IconButton
            sx={iconButton}
            onClick={() => history(ROUTE_PATH_PROFILE_AVATAR)}
          >
            <EditIcon sx={editIcon} />
          </IconButton>
        }
      >
        <Avatar sx={avatar} alt={userData.name} src={currentAvatarImage} />
      </Badge>

      <Box>
        <Typography sx={userName}>{userData.name}</Typography>
        <Box sx={status}>
          <Typography sx={statusTitle}>{userData.investmentStatus}</Typography>
          {userData.investmentStatus && (
            <img src={goldenCup} alt={userData.investmentStatus} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const NavigationTabs = (props: InternalNavigationProps) => {
  const { currentlySelected, setCurrentlySelected, searchParams } = props;

  const { translate } = useTranslations();
  const { search } = useLocation();

  const params = getQueryParams('tab', searchParams);
  const tabIndex = getTabIndex(params);

  return (
    <Tabs
      value={tabIndex || currentlySelected}
      orientation="vertical"
      sx={tabsContainer}
    >
      {internalNavigationTabs.map(({ title }, index: number) => (
        <Tab
          key={`tab-${title}`}
          sx={tab}
          label={translate(`navigation.${title}`)}
          onClick={() => {
            setCurrentlySelected(index);
            setQueryParams(search, title, 'tab');
          }}
        />
      ))}
    </Tabs>
  );
};

const NavigationTiles = (props: InternalNavigationTilesProps) => {
  const { translate } = useTranslations();
  const { setCurrentlySelected } = props;
  const { search } = useLocation();

  return (
    <Box sx={tilesContainer}>
      {internalNavigationTabs.map(({ title, image }, index: number) => (
        <Box
          key={`tab-${title}`}
          sx={tile}
          onClick={() => {
            setCurrentlySelected(index);
            setQueryParams(search, title, 'tab');
          }}
          aria-label={translate(`navigation.${title}`)}
        >
          <Box sx={tileImageContainer}>
            <img
              style={tileImage}
              src={image}
              alt={translate('altText.upload')}
            />
          </Box>
          <Typography align="center">
            {translate(`navigation.${title}`)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export const InternalNavigationMenu = (props: InternalNavigationProps) => {
  const { currentlySelected, setCurrentlySelected, searchParams } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper
      sx={isMobile ? internalNavigationTilesMenu : internalNavigationTabsMenu}
    >
      <ProfileInfo />
      {isMobile ? (
        <NavigationTiles setCurrentlySelected={setCurrentlySelected} />
      ) : (
        <NavigationTabs
          currentlySelected={currentlySelected}
          setCurrentlySelected={setCurrentlySelected}
          searchParams={searchParams}
        />
      )}
    </Paper>
  );
};
