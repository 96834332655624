import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from 'utils/hooks/query/useGetRequest';
import { useMutationRequest } from 'utils/hooks/query/useMutationRequest';
import { Api, MultiAvatarResponse } from 'api/types/userTypes';
import { userServiceClient } from 'api/apiClient';

export const getAvatars = (): number[] => {
  const { data: avatars } = useGetRequest({
    func: async () => (await userServiceClient.api.getAvatars()).data,
    key: nameof<Api<unknown>>((x) => x.api.getAvatars),
  });

  return (avatars as MultiAvatarResponse)?.data?.avatarIds as number[];
};

export const getDefaultAvatarById = (id: number): File => {
  const params = { id };

  const { data: avatar } = useGetRequest({
    func: async () =>
      (await userServiceClient.api.getDefaultAvatarById(id)).data,
    key: nameof<Api<unknown>>((x) => x.api.getDefaultAvatarById(id)),
    params,
  });

  return avatar as File;
};

export const getUserAvatarById = (id: number): File => {
  const params = { id };

  const { data: avatar } = useGetRequest({
    func: async () => {
      if (!id) {
        return null;
      }
      return (await userServiceClient.api.getUserAvatarById(id)).data;
    },
    key: nameof<Api<unknown>>((x) => x.api.getUserAvatarById(id)),
    params,
  });

  return avatar as File;
};

export const updateAvatar = (id: number) =>
  useMutationRequest(
    (request: File) =>
      userServiceClient.api.updateAvatar(id, { file: request }),
    nameof<Api<unknown>>((x) => x.api.updateAvatar)
  );
