import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, TextField, Typography } from '@mui/material';

import { getUserIdentityDocument } from 'api/controllers/IdentityDocumentController';
import ChangeDocumentImage from 'assets/images/change-document.svg';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { formatDate } from 'utils/helpers/formatDateHelper';
import { verificationBasedOnActions } from 'utils/helpers/verification';
import { SHUFTI_ACTION } from 'utils/constants/verification';
import { inputFieldWrapper, textField } from 'styles/verificationStyles';
import { textFieldLabel, textFieldWrapper } from 'styles/inputFieldsStyles';
import {
  changeDocumentInputWrapper,
  changeDocumentText,
  changeDocumentWrapper,
  documentsSubtitle,
  image,
} from 'styles/documentsStyles';

export const CurrentDocument = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const history = useNavigate();

  const userDocument = getUserIdentityDocument(userData.userId as number);

  return (
    <Box>
      <Typography variant="h6" sx={documentsSubtitle}>
        {translate('labels.currentDocument')}
      </Typography>

      {userDocument ? (
        <Box sx={textFieldWrapper}>
          <Box sx={{ ...inputFieldWrapper, ...changeDocumentInputWrapper }}>
            <Typography sx={textFieldLabel}>
              {translate('labels.documentType')}
            </Typography>

            <TextField
              sx={textField}
              variant="standard"
              type="text"
              disabled
              value={userDocument.documentType}
            />

            <Box sx={changeDocumentWrapper}>
              <Typography variant="body2" sx={changeDocumentText}>
                {translate('labels.changeDocument')}
              </Typography>
              <Box
                onClick={() =>
                  verificationBasedOnActions(SHUFTI_ACTION, history)
                }
              >
                <img
                  src={ChangeDocumentImage}
                  alt={translate('altText.changeDocument')}
                  width="32px"
                  style={image}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={inputFieldWrapper}>
            <Typography sx={textFieldLabel}>
              {translate('labels.expirationDate')}
            </Typography>

            <TextField
              sx={textField}
              variant="standard"
              type="text"
              disabled
              value={formatDate(userDocument.expirationDate as string)}
            />
          </Box>

          <Box sx={inputFieldWrapper}>
            <Typography sx={textFieldLabel}>
              {translate('labels.idNumber')}
            </Typography>

            <TextField
              sx={textField}
              variant="standard"
              type="text"
              disabled
              value={userDocument.idNumber}
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress color="primary" />
      )}
    </Box>
  );
};
