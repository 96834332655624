import {
  ROUTE_PATH_SMALL_QUESTIONNAIRE,
  ROUTE_PATH_VERIFICATION,
  ROUTE_PATH_PROFILE,
  ROUTE_PATH_SHUFTI,
} from 'utils/constants/routes';

export const getUrl = (action: string) => {
  switch (action) {
    case 'TRIGGER_ID_VERIFICATION':
      return ROUTE_PATH_SHUFTI;

    case 'FILL_SMALL_QUESTIONNAIRE':
      return ROUTE_PATH_SMALL_QUESTIONNAIRE;

    case 'FILL_COMPANY_QUESTIONNAIRE':
      return `${ROUTE_PATH_PROFILE}?tab=documents`;

    case 'REQUEST_PROOF_OF_ADDRESS':
      return `${ROUTE_PATH_PROFILE}?tab=documents`;

    case 'FILL_BIG_QUESTIONNAIRE':
      return `${ROUTE_PATH_PROFILE}?tab=documents`;

    case 'FILL_COMPANY_DETAILS':
      return `${ROUTE_PATH_PROFILE}?tab=profileSettings`;

    case 'REQUEST_DECLARATION_OF_FUNDS':
      return `${ROUTE_PATH_PROFILE}?tab=documents`;

    case 'REQUEST_OTHER_DOCUMENT':
      return `${ROUTE_PATH_PROFILE}?tab=documents`;

    default:
      window.location.href = `${ROUTE_PATH_PROFILE}?tab=profileSettings`;
      return null;
  }
};

export const verificationBasedOnActions = (action: string, history: any) => {
  history(getUrl(action));
};

export const verificationBasedOnStatus = (
  status: string,
  action: string,
  history: any
) => {
  if (status === 'ONBOARDING') {
    history(ROUTE_PATH_VERIFICATION);
  } else {
    verificationBasedOnActions(action, history);
  }
};
