import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { useTranslations } from 'utils/context/TranslationContext';
import { ROUTE_PATH_PROFILE_AVATAR } from 'utils/constants/routes';
import { updateAvatar } from 'api/controllers/AvatarController';
import { backButton, backButtonArrow } from 'styles/internalNavigationStyles';
import { MainWrapper } from 'components/common/MainWrapper';
import { FileUploadComponent } from 'components/common/inputFields/FileUploadComponent';
import { useUserData } from 'utils/context/UserDataContext';
import { IMAGE_ACCEPT_FILES } from 'utils/constants/inputs';
import { uploadWrapper } from 'styles/inputFieldsStyles';

export const UploadAvatarScreen = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const navigate = useNavigate();

  const updateUserAvatar = updateAvatar(userData.userId as number);

  const uploadFile = (file: File[]) => {
    updateUserAvatar.mutateAsync(file[0]).then(() => {
      navigate(ROUTE_PATH_PROFILE_AVATAR);
    });
  };

  return (
    <MainWrapper>
      <Button
        sx={backButton}
        variant="text"
        onClick={() => navigate(ROUTE_PATH_PROFILE_AVATAR)}
      >
        <ArrowBackIos sx={backButtonArrow} />
        {translate('buttons.back')}
      </Button>

      <Box sx={uploadWrapper}>
        <FileUploadComponent
          id="uploadAvatar"
          uploadFile={uploadFile}
          uploadFileMessage={translate('labels.uploadMessageAvatar')}
          acceptFile={IMAGE_ACCEPT_FILES}
        />
      </Box>
    </MainWrapper>
  );
};
