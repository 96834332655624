import { IUVO_PLATFORM_PATH } from 'utils/constants/mainNavigation';

// TODO: refactor paths when backend is ready
export const mainNavigationConfig = [
  {
    path: IUVO_PLATFORM_PATH,
    title: 'dashboard',
  },
  {
    path: IUVO_PLATFORM_PATH,
    title: 'save',
  },
  {
    path: IUVO_PLATFORM_PATH,
    title: 'p2p',
  },
  {
    path: IUVO_PLATFORM_PATH,
    title: 'myInvestments',
  },
  {
    path: IUVO_PLATFORM_PATH,
    title: 'accountStatement',
  },
  {
    path: IUVO_PLATFORM_PATH,
    title: 'currencyExchange',
  },
];
