import React from 'react';

import { Typography } from '@mui/material';
import { pageTitle } from 'styles/commonStyles';

interface PageTitleProps {
  title: string;
  sx?: {};
}

export const PageTitle = (props: PageTitleProps) => {
  const { title, sx } = props;

  return (
    <Typography variant="h5" sx={[pageTitle, sx || null]}>
      {title}
    </Typography>
  );
};
