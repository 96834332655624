import React from 'react';

import {
  UseFormGetValues,
  FieldValues,
  UseFormResetField,
} from 'react-hook-form';

import { InputAdornment, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { icons, submitCheckButton } from 'styles/inputFieldsStyles';

interface SingleEditIconsProps {
  id: string;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  resetField: UseFormResetField<FieldValues>;
  temporalInputValue: string | undefined;
  setTemporalInputValue: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getValues: UseFormGetValues<FieldValues>;
  defaultValue?: string;
}

export const SingleEditIcons = (props: SingleEditIconsProps) => {
  const {
    id,
    isEditing,
    setIsEditing,
    resetField,
    temporalInputValue,
    setTemporalInputValue,
    getValues,
    defaultValue,
  } = props;

  const handleClose = () => {
    setIsEditing(false);
    resetField(id, { defaultValue: temporalInputValue });
  };

  const handleEdit = () => {
    setIsEditing(true);
    setTemporalInputValue(getValues(id) || defaultValue);
  };

  return (
    <InputAdornment position="end">
      {isEditing ? (
        <>
          <Button
            disableRipple
            sx={submitCheckButton}
            type="submit"
            endIcon={<CheckIcon sx={icons} />}
          />
          <CloseIcon sx={icons} onClick={handleClose} />
        </>
      ) : (
        <EditIcon sx={icons} onClick={handleEdit} />
      )}
    </InputAdornment>
  );
};
