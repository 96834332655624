export const mainWrapper = {
  width: '100%',
  maxWidth: '1240px',
  margin: '0 auto',
  padding: { xs: '16px', md: '24px 40px' },
};

export const mainWrapperCommon = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  maxWidth: '1400px',
  padding: { xs: '8px 16px', lg: '8px 40px' },
};

export const mainWrapperProfile = {
  display: 'flex',
  justifyContent: { xs: 'flex-start', md: 'flex-end' },
  flexWrap: { xs: 'wrap', md: 'nowrap' },
  gap: { md: '60px' },
};
