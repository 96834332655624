export const referralCardWrapper = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  gap: '30px',
};

export const referralCard = {
  display: 'flex',
  flexDirection: 'column',
  width: { xs: '100%', md: '50%' },
  padding: '16px',
};

export const socialMediaCardBackground = {
  background: '#F1F6F7',
};

export const emailCardBackground = {
  background: '#E1E7E7',
};

export const headerSocialMediaCardSection = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
};

export const shareIconContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  color: 'text.secondary',
  background: '#17AA4F',
  borderRadius: '50%',
};

export const referralCardTitle = {
  lineHeight: '18px',
  fontSize: '18px',
  fontFamily: 'primaryBold',
};

export const bodyCardWrapper = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '0 32px',
};

export const socialMediaIconsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: { xs: '8px 0', lg: '8px 0 32px' },
};

export const referralLink = {
  margin: '8px 0 16px',
  '& .MuiInputBase-root': {
    fontSize: '16px',
    '&.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
  },
  '& .MuiInputBase-input': {
    textOverflow: 'ellipsis',
  },
};

export const emailField = {
  '& .MuiInputBase-root:before': {
    borderBottom: '1px solid #2b292959',
  },
};
export const textAreaEmailMessage = {
  width: '100%',
  height: '80px',
  margin: '16px 0 8px',
  padding: '8px',
  resize: 'none' as 'none',
  outline: 'none' as 'none',
  overflowY: 'auto' as 'auto',
  fontSize: '16px',
  fontFamily: 'primaryRegular' as 'primaryRegular',
};

export const langMenuLabel = {
  fontSize: '16px',
};

export const emailLanguageWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
};

export const referralButton = {
  width: 'auto',
  maxWidth: 'none',
  margin: '0 auto',
  fontSize: { xs: '12px', sm: '16px' },
};

export const socialMediaButtonMТ = {
  marginTop: '24px',
};

export const invitationLanguage = {
  '& .MuiSelect-select.MuiSelect-outlined': {
    padding: '4px 24px',
    fontSize: '16px',
  },
  '& .MuiSvgIcon-root': {
    right: '0',
  },
};

export const errorMessage = {
  fontSize: '14px',
  color: 'error.main',
};
