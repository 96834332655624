import { nameof } from 'ts-simple-nameof';

import { userServiceClient } from 'api/apiClient';
import { useGetRequest } from 'utils/hooks/query/useGetRequest';
import {
  Api,
  DocumentsHistory,
  DocumentsHistoryResponse,
  Error,
  RequestedDocuments,
  RequestedDocumentsResponse,
} from 'api/types/userTypes';
import { appConfig } from 'utils/config/app.config';
import { ACCESS_TOKEN, BEARER_TOKEN } from 'utils/constants/common';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { successResultHandler } from 'utils/helpers/successResultHandler';
import { errorResultHandler } from 'utils/helpers/errorResultHandler';
import { MESSAGE_SUCCESS } from 'utils/constants/messages';
import { openPdf } from 'utils/helpers/openPdfHelper';
import { useMutationRequest } from 'utils/hooks/query/useMutationRequest';

export const getUserRequestedDocuments = (id: number): RequestedDocuments => {
  const params = { id };

  const { data: documents } = useGetRequest({
    func: async () => {
      if (!id) {
        return null;
      }
      return (await userServiceClient.api.getRequestedDocuments(id)).data;
    },
    key: nameof<Api<unknown>>((x) => x.api.getRequestedDocuments(id)),
    params,
  });

  return (documents as RequestedDocumentsResponse)?.data as RequestedDocuments;
};

export const getUserDocumentsHistory = (id: number): DocumentsHistory => {
  const params = { id };

  const { data: documents } = useGetRequest({
    func: async () => {
      if (!id) {
        return null;
      }
      return (await userServiceClient.api.getUserDocumentsHistory(id)).data;
    },
    key: nameof<Api<unknown>>((x) => x.api.getUserDocumentsHistory(id)),
    params,
  });

  return (documents as DocumentsHistoryResponse)?.data as DocumentsHistory;
};

export const getUserDocumentPdf = async (
  userId: number,
  documentId: number
) => {
  const url = `${appConfig.userServiceApiUrl}/api/users/${userId}/documents/${documentId}`;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const requestHeaders = {
    headers: {
      Authorization: `${BEARER_TOKEN} ${token}`,
    },
  };

  await fetch(url, requestHeaders)
    .then((response) => {
      if (response.status === HttpStatusCodes.Success) {
        openPdf(response);

        successResultHandler(response, MESSAGE_SUCCESS);
      }
    })
    .catch((error: Error) => {
      if (!error.detail) {
        errorResultHandler({ detail: (error as { message: string }).message });
      } else {
        errorResultHandler(error);
      }
    });
};

export const uploadDocument = (id: number) =>
  useMutationRequest(
    (request: { file: File; documentName: string }) =>
      userServiceClient.api.uploadDocument(id, {
        file: request.file,
        documentName: request.documentName,
      }),
    nameof<Api<unknown>>((x) => x.api.uploadDocument)
  );
