import React, { useState } from 'react';

import { FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  FormHelperText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { checkbox, errorMessage } from 'styles/inputFieldsStyles';
import { useTranslations } from "utils/context/TranslationContext";

interface CheckboxProps {
  label: string;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

export const CheckboxComponent = (props: CheckboxProps) => {
  const { label, required, register, setValue, errors } = props;

  const { translate } = useTranslations();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const modifiedLabel = required ? `${label} *` : `${label}`;

  return (
    <FormControl
      {...register('checkbox', {
        validate: (value: boolean) => required && value
      })}
      error={!!errors.checkbox}
    >
      <FormGroup>
        <FormControlLabel
          label={modifiedLabel}
          control={
            <Checkbox
              onChange={() => {
                setIsCheckboxChecked(!isCheckboxChecked);
                setValue('checkbox', !isCheckboxChecked)
              }}
              checked={isCheckboxChecked}
              checkedIcon={<CheckIcon sx={checkbox} />}
            />
          }
        />
      </FormGroup>

      <FormHelperText sx={errorMessage}>
        {!!errors.checkbox && translate('messages.checkboxErrorMessage')}
      </FormHelperText>
    </FormControl>
  );
};
