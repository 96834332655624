import { nameof } from 'ts-simple-nameof';

import { useGetRequest } from 'utils/hooks/query/useGetRequest';
import { useMutationRequest } from 'utils/hooks/query/useMutationRequest';
import { userServiceClient } from 'api/apiClient';
import {
  Api,
  PreFilledQuestionResponse,
  Result,
  SinglePreFilledFormResponse,
} from 'api/types/userTypes';

export const getFormByUserIdAndFormName = (
  id: number,
  formName: string
): PreFilledQuestionResponse[] => {
  const params = { id, formName };

  const { data: form } = useGetRequest({
    func: async () => {
      if (!id) {
        return null;
      }
      return (
        await userServiceClient.api.getFormByUserIdAndFormName(id, formName)
      ).data;
    },
    key: nameof<Api<unknown>>((x) => x.api.getFormByUserIdAndFormName),
    params,
  });

  return (form as SinglePreFilledFormResponse)?.data
    ?.questions as PreFilledQuestionResponse[];
};

export const submitForm = (id: number) =>
  useMutationRequest(
    (data: Result) => userServiceClient.api.submitForm(id, data),
    nameof<Api<unknown>>((x) => x.api.submitForm)
  );
