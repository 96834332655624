import React from 'react';

import { PageTitle } from 'components/common/PageTitle';
import { MainWrapper } from 'components/common/MainWrapper';

export const BankTransferScreen = () => (
  <MainWrapper>
    <PageTitle title="Bank Transfer Screen" />
  </MainWrapper>
);
