import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { mainWrapper } from 'styles/mainWrapperStyles';

interface MainWrapperProps {
  children: ReactNode;
  styles?: {
    [key: string]: {};
  };
}

export const MainWrapper = (props: MainWrapperProps) => {
  const { children, styles } = props;

  return <Box sx={{ ...mainWrapper, ...styles }}>{children}</Box>;
};
