import React from 'react';

import { Link } from '@mui/material';

import { useTranslations } from 'utils/context/TranslationContext';
import { mainNavigationConfig } from 'utils/data/mainNavigationConfig';
import { link } from 'styles/mainNavigationStyles';

export const NavigationItems = () => {
  const { translate } = useTranslations();

  return (
    <>
      {mainNavigationConfig.map(({ path, title }) => (
        <Link
          key={title}
          href={path}
          underline="none"
          target="_blank"
          sx={link}
        >
          {translate(`navigation.${title}`)}
        </Link>
      ))}
    </>
  );
};
