import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Typography } from '@mui/material';

import { getUserRequestedDocuments } from 'api/controllers/DocumentsController';
import DocumentImage from 'assets/images/declaration.svg';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { ROUTE_PATH_UPLOAD_DOCUMENT } from 'utils/constants/routes';
import {
  documentsSubtitle,
  horizontalLine,
  missingDataMessage,
  requestedDocumentsDetails,
  requestedDocumentsImageWrapper,
  requestedDocumentsTitle,
  requestedDocumentsTitles,
  requestedDocumentsWrapper,
} from 'styles/documentsStyles';

export const RequestedDocuments = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const history = useNavigate();

  const requestedDocuments = getUserRequestedDocuments(
    userData.userId as number
  );

  //   TODO: open form
  const showForm = () => {};

  //   TODO: upload document
  const uploadDocument = (documentName: string) => {
    localStorage.setItem('documentName', documentName);
    history(ROUTE_PATH_UPLOAD_DOCUMENT);
  };

  return (
    <Box>
      <Typography variant="h6" sx={documentsSubtitle}>
        {translate('labels.requestedDocuments')}
      </Typography>

      {requestedDocuments ? (
        <Box>
          {requestedDocuments.length > 0 ? (
            requestedDocuments.map((document) => (
              <Box key={document.documentType}>
                <Box sx={requestedDocumentsWrapper}>
                  <Box sx={requestedDocumentsTitles}>
                    <Typography variant="h6" sx={requestedDocumentsTitle}>
                      {document.title}
                    </Typography>

                    <Typography variant="body2" sx={requestedDocumentsDetails}>
                      {document.details}
                    </Typography>
                  </Box>

                  <Box
                    sx={requestedDocumentsImageWrapper}
                    onClick={
                      document.isForm
                        ? showForm
                        : () => uploadDocument(document.documentName as string)
                    }
                  >
                    <img
                      src={DocumentImage}
                      alt={translate('altText.document')}
                      width="30px"
                    />
                  </Box>
                </Box>
                <hr style={horizontalLine} />
              </Box>
            ))
          ) : (
            <Typography sx={missingDataMessage}>
              {translate('labels.noRequestedDocuments')}
            </Typography>
          )}
        </Box>
      ) : (
        <CircularProgress color="primary" />
      )}
    </Box>
  );
};
