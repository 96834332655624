import React, { useState } from 'react';

import { FieldErrors, FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Typography, Box, TextField, Autocomplete } from '@mui/material';

import {
  AnswerResponse,
  PreFilledQuestionResponse,
  UserAnswer,
} from 'api/types/userTypes';
import {
  nestedQuestion,
  textField,
  autocompleteInputField,
  autocompleteFieldLabel,
  nestedQuestionWrapper,
  inputFieldWrapper,
} from 'styles/verificationStyles';
import { useTranslations } from "utils/context/TranslationContext";
import { inputError } from "styles/commonStyles";

interface SelectFieldProps {
  question: PreFilledQuestionResponse;
  addAnswer: (answer: UserAnswer) => void;
  deleteAnswer: (answer: UserAnswer) => void;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

interface AdditionalQuestionProps {
  content: string;
  value: string;
}

export const QuestionDropdown = (props: SelectFieldProps) => {
  const { question, addAnswer, deleteAnswer, register, setValue, errors, clearErrors } = props;
  const { translate } = useTranslations();
  const questionAnswerInitial = { questionId: question.id as number };

  const [questionValue, setQuestionValue] = useState<AnswerResponse | null>(
    null
  );

  const [additionalQuestion, setAdditionalQuestion] =
    useState<AdditionalQuestionProps | null>(null);

  const [questionAnswer, setQuestionAnswer] = useState<UserAnswer>({
    ...questionAnswerInitial,
  });

  const onAutocompleteChange = (value: AnswerResponse) => {
    setQuestionValue(value);

    if (value) {
      const newQuestionAnswer = {
        ...questionAnswer,
        answerId: value.id,
      };

      setQuestionAnswer(newQuestionAnswer);
      // add answer
      addAnswer(newQuestionAnswer);
    } else {
      setQuestionAnswer({ ...questionAnswerInitial });
      // delete answer
      deleteAnswer({ ...questionAnswerInitial });
    }

    if (value && value.additionalQuestionContent) {
      setAdditionalQuestion({
        content: value.additionalQuestionContent,
        value: '',
      });
      // if additional question exists, add only question id to user answer
      addAnswer(questionAnswerInitial);
    } else {
      setAdditionalQuestion(null);
    }
  };

  const onAdditionalQuestionBlur = (value: string) => {
    if (value.length > 0) {
      const newQuestionAnswer = {
        ...questionAnswer,
        additionalQuestionAnswer: value as string,
      };

      setQuestionAnswer(newQuestionAnswer);
      // add answer
      addAnswer(newQuestionAnswer);
    } else {
      const currentQuestionAnswerState = { ...questionAnswer };
      delete currentQuestionAnswerState.additionalQuestionAnswer;
      setQuestionAnswer(currentQuestionAnswerState);
      // delete answer
      deleteAnswer(currentQuestionAnswerState);
    }
  };

  const onAdditionalQuestionChange = (value: string) => {
    const trimmedValue = value.trim();

    setAdditionalQuestion({
      content: additionalQuestion ? additionalQuestion.content : '',
      value: trimmedValue.length > 0 ? value : trimmedValue,
    });
  };

  return (
    <>
      <Box sx={inputFieldWrapper}>
        <Typography variant="h6" sx={autocompleteFieldLabel}>
          {question.content} *
        </Typography>

        <Autocomplete
          sx={autocompleteInputField}
          options={question.answers as AnswerResponse[]}
          getOptionLabel={(option: AnswerResponse) => option.content as string}
          value={questionValue as AnswerResponse}
          isOptionEqualToValue={(option, checkValue) =>
            // check whether content is the same or is empty in case clearable button has been used
            option.content === checkValue.content || checkValue.content === ''
          }
          onChange={(e, newValue) => {
            onAutocompleteChange(newValue as AnswerResponse);
            setValue(`question${question.id}`, newValue?.content, { shouldValidate: true });
            // clear additional question error on dropdown value change
            clearErrors(`additionalQuestion${question.id}`);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register(`question${question.id}`, {
                required: translate('messages.requiredFieldMessage'),
              })}
              sx={inputError}
              variant="standard"
              helperText={errors[`question${question.id}`]?.message as string}
              error={!!errors[`question${question.id}`]?.message}
            />
          )}
        />
      </Box>

      {additionalQuestion && (
        <Box sx={{ ...inputFieldWrapper, ...nestedQuestionWrapper }}>
          <Typography
            variant="h6"
            sx={{ ...autocompleteFieldLabel, ...nestedQuestion }}
          >
            {additionalQuestion.content} *
          </Typography>

          <TextField
            sx={textField}
            variant="standard"
            type="text"
            value={additionalQuestion.value}
            {...register(`additionalQuestion${question.id}`, {
              required: translate('messages.requiredFieldMessage')
            })}
            helperText={errors[`additionalQuestion${question.id}`]?.message as string}
            error={!!errors[`additionalQuestion${question.id}`]?.message}
            onBlur={(e) => {
              onAdditionalQuestionBlur(e.target.value);
            }}
            onChange={(e) => {
              onAdditionalQuestionChange(e.target.value);
              setValue(`additionalQuestion${question.id}`, e.target.value);
            }}
          />
        </Box>
      )}
    </>
  );
};
