import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { MainWrapper } from 'components/common/MainWrapper';
import { useTranslations } from 'utils/context/TranslationContext';
import { verificationBasedOnStatus } from 'utils/helpers/verification';
import { useUserData } from 'utils/context/UserDataContext';
import { mainWrapperCommon } from 'styles/mainWrapperStyles';
import {
  button,
  warningIcon,
  warningMessageContainer,
  wrapper,
  warningMessage,
} from 'styles/verificationMessage';

export const VerificationMessage = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();
  const history = useNavigate();

  return (
    <Box sx={wrapper}>
      <MainWrapper styles={mainWrapperCommon}>
        <Box sx={warningMessageContainer}>
          <WarningIcon sx={warningIcon} />
          <Typography variant="h2" sx={warningMessage}>
            {translate('messages.incompleteVerification')}
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            color="secondary"
            sx={button}
            onClick={() =>
              verificationBasedOnStatus(
                userData.userStatus as string,
                (userData.requiredActions as Array<{ type: string }>)[0].type,
                history
              )
            }
          >
            {translate('buttons.verification')}
          </Button>
        </Box>
      </MainWrapper>
    </Box>
  );
};
