import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { TOAST_MESSAGE_DURATION_MS } from 'utils/constants/options';

export const ToastMessage = () => (
  <ToastContainer
    autoClose={TOAST_MESSAGE_DURATION_MS}
    hideProgressBar
    newestOnTop
    closeOnClick
    transition={Slide}
    pauseOnFocusLoss={false}
  />
);
