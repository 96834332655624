import FacebookIcon from 'assets/images/social-media/facebook.svg';
import TwitterIcon from 'assets/images/social-media/twitter.svg';
import LinkedInIcon from 'assets/images/social-media/linkedin.svg';

export const socialMediaIcons = [
  {
    label: 'LinkedIn',
    imageSrc: LinkedInIcon,
  },
  {
    label: 'Twitter',
    imageSrc: TwitterIcon,
  },
  {
    label: 'Facebook',
    imageSrc: FacebookIcon,
  },
];
