import React from 'react';

import { Box, Typography } from '@mui/material';

import { ZERO_FUNDS_VALUE } from 'utils/constants/header';
import { fundValue, fundsWrapper } from 'styles/headerStyles';
import { Funds } from 'api/types/userTypes';

interface HeaderFundsProps {
  funds: Funds | undefined;
  additionalStyle?: {
    [key: string]: string;
  };
}

export const HeaderFunds = (props: HeaderFundsProps) => {
  const { funds, additionalStyle } = props;

  return (
    <Box sx={{ ...fundsWrapper, ...additionalStyle }}>
      {funds && funds.length === 0 ? (
        <Typography sx={fundValue}>{ZERO_FUNDS_VALUE}</Typography>
      ) : (
        funds &&
        funds.map((value) => (
          <Typography sx={fundValue} key={value.currencyId}>
            {value.parsedAmount}
          </Typography>
        ))
      )}
    </Box>
  );
};
