import React from 'react';

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  getUserDocumentPdf,
  getUserDocumentsHistory,
} from 'api/controllers/DocumentsController';
import PdfImage from 'assets/images/pdf-file.svg';
import FileIcon from 'assets/images/file-icon.svg';
import { useUserData } from 'utils/context/UserDataContext';
import { useTranslations } from 'utils/context/TranslationContext';
import { formatDate } from 'utils/helpers/formatDateHelper';
import {
  tableBodyCell,
  documentsSubtitle,
  documentsHistoryWrapper,
  imageTableCell,
  imageTextTableCell,
  tableHeadCell,
  missingDataMessage,
  image,
} from 'styles/documentsStyles';

export const DocumentsHistory = () => {
  const { translate } = useTranslations();
  const { userData } = useUserData();

  const userDocumentHistory = getUserDocumentsHistory(
    userData.userId as number
  );

  const openPdf = (documentId: number) => {
    getUserDocumentPdf(userData.userId as number, documentId);
  };

  return (
    <Box sx={documentsHistoryWrapper}>
      <Typography variant="h6" sx={documentsSubtitle}>
        {translate('labels.documentsHistory')}
      </Typography>

      {userDocumentHistory ? (
        <Box>
          {Object.entries(userDocumentHistory).length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableHeadCell}>
                      {translate('labels.name')}
                    </TableCell>
                    <TableCell sx={tableHeadCell}>
                      {translate('labels.date')}
                    </TableCell>
                    <TableCell sx={tableHeadCell}>
                      {translate('labels.status')}
                    </TableCell>
                    <TableCell sx={{ ...tableHeadCell, ...imageTableCell }}>
                      {translate('labels.review')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {userDocumentHistory.map((document) => (
                    <TableRow key={document.id}>
                      <TableCell>
                        <Box sx={imageTextTableCell}>
                          <img
                            src={PdfImage}
                            alt={translate('altText.pdf')}
                            width="35px"
                          />
                          <Typography variant="body1" sx={tableBodyCell}>
                            {document.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={tableBodyCell}>
                        {formatDate(document.date as string)}
                      </TableCell>
                      <TableCell sx={tableBodyCell}>
                        {document.status}
                      </TableCell>
                      <TableCell
                        sx={imageTableCell}
                        onClick={() => openPdf(document.id as number)}
                      >
                        <img
                          src={FileIcon}
                          alt={translate('altText.file')}
                          style={image}
                          width="30px"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={missingDataMessage}>
              {translate('labels.noDocumentsHistory')}
            </Typography>
          )}
        </Box>
      ) : (
        <CircularProgress color="primary" />
      )}
    </Box>
  );
};
