import { ID_ERROR } from 'utils/constants/identifiers';
import { MESSAGE_UNEXPECTED_ERROR } from 'utils/constants/messages';
import { showErrorToast } from 'utils/helpers/toastHelper';
import { ROUTE_PATH_HOME } from 'utils/constants/routes';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { Error } from 'utils/interfaces/ErrorProps';
import { customHistory } from 'App';
import { registerUser } from 'api/controllers/UserController';

export const errorResultHandler = (responseError: Error) => {
  // We check if in responseError has details.
  // If it has that means the error comes from viravaService
  // !!! If error comes from viravaService it comes like different object. !!!
  // !!! Thats why we handle it in different way. !!!
  if (responseError.detail) {
    return showErrorToast(responseError.detail);
  }

  // We get the error object from response here
  // We do it this way because from response obj we got:
  // Response: {error: error: {details, status ...}}
  // and we return error based on status code.
  const error = responseError[ID_ERROR]?.error as Error;

  if (!error || !error.detail || !error.status) {
    return showErrorToast(MESSAGE_UNEXPECTED_ERROR);
  }

  switch (error.status) {
    case HttpStatusCodes.Forbidden:
      customHistory.replace(ROUTE_PATH_HOME);
      return showErrorToast(error.detail);
    case HttpStatusCodes.ExpectationFailed:
      return registerUser();
    default:
      return showErrorToast(error.detail);
  }
};
