import { nameof } from 'ts-simple-nameof';

import {
  Api,
  LoginResponse,
  UserData,
  UserDataResponse,
  UserLoginData,
  UserProfilePatch,
} from 'api/types/userTypes';
import { userServiceClient } from 'api/apiClient';
import { appConfig } from 'utils/config/app.config';
import { errorResultHandler } from 'utils/helpers/errorResultHandler';
import { ACCESS_TOKEN, BEARER_TOKEN } from 'utils/constants/common';
import { MESSAGE_SUCCESS_UPDATED } from 'utils/constants/messages';
import { useGetRequest } from 'utils/hooks/query/useGetRequest';
import { useMutationRequest } from 'utils/hooks/query/useMutationRequest';

const registerUrl = `${appConfig.userServiceApiUrl}/api/register`;

export const registerUser = async () => {
  const response = await fetch(registerUrl, {
    method: 'POST',
    headers: {
      Authorization: `${BEARER_TOKEN} ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  });

  const { error } = await response.json();

  if (error) {
    errorResultHandler(error);
  }
};

export const loginUser = (): UserLoginData => {
  const { data: login } = useGetRequest({
    func: async () => (await userServiceClient.api.login()).data,
    key: nameof<Api<unknown>>((x) => x.api.login()),
  });

  return (login as LoginResponse)?.data as UserLoginData;
};

export const getUserById = (id: number): UserData => {
  const params = { id };

  const { data: users } = useGetRequest({
    func: async () => (await userServiceClient.api.getUserData(id)).data,
    key: nameof<Api<unknown>>((x) => x.api.getUserData(id)),
    params,
  });

  return (users as UserDataResponse)?.data as UserData;
};

export const getUserProfile = (id: number): UserData => {
  const params = { id };

  const { data: users } = useGetRequest({
    func: async () => (await userServiceClient.api.getUserProfile(id)).data,
    key: nameof<Api<unknown>>((x) => x.api.getUserProfile(id)),
    params,
  });

  return (users as UserDataResponse)?.data as UserData;
};

export const patchUserProfile = (id: number) =>
  useMutationRequest(
    (request: UserProfilePatch) =>
      userServiceClient.api.patchUserProfile(id, request),
    [nameof<Api<unknown>>((x) => x.api.getUserProfile(id))],
    MESSAGE_SUCCESS_UPDATED
  );
