import React, { useState } from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import {
  avatar,
  avatarBackground,
  noUserInfoWrapper,
  userAccountId,
  userInfoWrapper,
  userName,
} from 'styles/headerStyles';
import { useTranslations } from 'utils/context/TranslationContext';
import { useUserData } from 'utils/context/UserDataContext';
import { getUserAvatarById } from 'api/controllers/AvatarController';
import { blobToFileHelper } from 'utils/helpers/blobToFileHelper';

interface ProfileInfoProps {
  show?: boolean;
}

export const ProfileInfo = (props: ProfileInfoProps) => {
  const { show = true } = props;

  const { translate } = useTranslations();
  const { userData } = useUserData();

  const [currentAvatarImage, setCurrentAvatarImage] = useState('');

  const getCurrentAvatarImage = getUserAvatarById(userData.avatarId as number);

  if (getCurrentAvatarImage) {
    blobToFileHelper(getCurrentAvatarImage, setCurrentAvatarImage);
  }

  return (
    <>
      <Avatar
        sx={currentAvatarImage ? { ...avatar, ...avatarBackground } : avatar}
        alt={userData.name}
        src={currentAvatarImage}
      />
      {show && (
        <Box
          sx={
            userData.name || userData.userId
              ? userInfoWrapper
              : noUserInfoWrapper
          }
        >
          {userData.name && (
            <Typography sx={userName}>{userData.name}</Typography>
          )}
          {userData.userId && (
            <Typography sx={userAccountId}>
              {translate('labels.accountId', {
                number: userData.userId.toString(),
              })}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
