export const appBar = {
  position: 'unset',
  display: { md: 'flex', xs: 'none' },
  flexDirection: 'row',
  height: '50px',
  boxShadow: 'rgba(0, 0, 0, 0.25)',
  backgroundColor: 'background.default',
};

// Logo
export const logoWrapper = {
  display: 'flex',
  alignItems: 'center',
};

export const logoImage = {
  maxHeight: '40px',
};

// Navigation Links
export const linksContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: { sm: '16px', lg: '24px' },
};

export const link = {
  color: 'text.primary',
  fontSize: '16px',
  fontFamily: 'primarySemiBold',
  '&:hover': {
    color: 'primary.main',
  },
};
