import React, { ReactNode, useContext, useMemo, useState } from 'react';

import { USER_DATA_CONFIG } from 'utils/constants/context/profileContext';
import { UserLogin } from 'api/types/userTypes';

type UserData = {
  userData: UserLogin;
  setData: (userData: UserLogin) => void;
};

const UserDataContext = React.createContext<UserData>({
  userData: USER_DATA_CONFIG,
  setData: () => '',
});

interface UserDataProps {
  readonly children: ReactNode;
}

export const UserDataProvider = ({ children }: UserDataProps) => {
  const [userData, setUserData] = useState<UserLogin>(USER_DATA_CONFIG);

  const setData = (newData: UserLogin) => {
    setUserData(newData);
  };

  const context = useMemo(
    () => ({
      userData,
      setData,
    }),
    [userData.userId]
  );

  return (
    <UserDataContext.Provider value={context}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = (): UserData => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error('useUserData must be used with-in a UserDataProvider');
  }

  return context;
};
